import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";

interface UserDetails {
    attributes: {
        first_name: string;
        role: string;
        image: {
            url: string;
        };
    };
}
interface UserDetailsResponse {
    data: UserDetails;
    meta: {
        message: string;
    };
    error?: string[];
    success: string[]
}
interface Image {
    name: string;
    url: string;
    size: string;
  }
  interface InterviewPrepMaterial {
    name: string;
    url: string;
    size: string;
  }
  
  interface Location {
    location: string;
  }
  
  interface Document {
    name: string;
    url: string;
    size: string;
  }
  
  interface DocumentsAndTemplates {
    [key: string]: Document[];
  }

  interface Logo {
    id: number;
    filename: string;
    url: string;
    type: string;
  }
  
  interface UniversityData {
      workflow: string;
      overview: string;
      eligibility: string;
      interview_prep_material: InterviewPrepMaterial[];
      images: Image[];
      documents_and_templates: DocumentsAndTemplates;
      locations: Location[];
      university_name: string
      uni_location: string;
      uni_country: string;
      ranking: string;
      institution_type: string;
      sponsor_license_number: string;
      offer_tat: boolean;
      application_fees: string;
      post_study_work_permit: boolean;
      conditional_offer_letter: boolean;
      logo:Logo;
      additional_services:string
  }

  interface UniversityLogo {
    id: number;
    filename: string;
    url: string;
    type: string;
  }
  
  interface University {
    id: number;
    university_name: string;
    location: string;
    province: string;
    country: string;
    associated_tags: string;
    offer_tat: string;
    year: number;
    logo: UniversityLogo;
  }
  
  interface Session {
    intake_label: string;
    months: string[];
    availability: string;
  }
  
  export interface Program {
    id: string;
    type: string;
    attributes: {
      program_name: string;
      program_level: string;
      duration: string;
      fees: string;
      fees_after_scholarship: string;
      cas_deposit: string;
      program_link: string;
      discipline: string;
      is_shortlisted: boolean;
      university: University;
      sessions: Session[];
    };
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userDetails: {
        attributes: {
            first_name: string;
            role: string;
            image: {
                url: string;
            },
        },
    },
    toggleDrawer: boolean,
    tabValue: number
    slideIndex: number,
    workflow: string;
    overview: string;
    eligibility: string;
    interviewPrepMaterial: InterviewPrepMaterial[];
    images: Image[];
    documentsAndTemplates: DocumentsAndTemplates;
    locations: Location[];
    university_name: string
    uni_location: string;
    uni_country: string;
    ranking: string;
    institution_type: string;
    sponsor_license_number: string;
    offer_tat: boolean;
    application_fees: string;
    post_study_work_permit: boolean;
    conditional_offer_letter: boolean;
    logo:Logo;
    additional_services:string,
    urlUniversityName: string,
    urlUniversityID: string,
    universityCourses: Program[];
    universityPrograms: string[];
    selectedPrograms: string[];
    universityDiscipline: string[];
    selectedDiscipline: string[];
    universityIntakes: string[];
    selectedIntakes: string[];
    searchTerm: string;
    searchResults: Program[],
    MangeShortListModel: boolean;
    shortListData: any,
    studentList:any,
    selectedStudent:any,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class InstitutionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProfileAPICallId: string = ""
    getInstituteAPICallId: string = ""
    getUniversityCourseListAPICallId: string = ""
    getUniversityProgramsAPICallId: string = ""
    getUniversityDisciplineAPICallId: string = ""
    getUniversityIntakesAPICallId: string = ""
    getUniversityFilterAPICallId: string = ""
    fetchAllStudentsAPICallId: string = ""
    uploadCourseAPICallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userDetails: {
                attributes: {
                    first_name: '',
                    role: '',
                    image: {
                        url: '',
                    },
                },
            },
            toggleDrawer: false,
            tabValue: 0,
            slideIndex: 0,
            workflow: "",
            overview: "",
            eligibility: "",
            interviewPrepMaterial: [],
            images: [],
            documentsAndTemplates: {},
            locations: [],
            university_name: "",
            uni_location: "",
            uni_country:"",
            ranking: "",
            institution_type: "",
            sponsor_license_number: "",
            offer_tat: false,
            application_fees: "",
            post_study_work_permit:false,
            conditional_offer_letter: false,
            logo:{
                id: 0,
                filename: "",
                url: "",
                type: "",
              },
            additional_services:"",
            urlUniversityName:"",
            urlUniversityID:"",
            universityCourses: [],
            universityPrograms:[],
            selectedPrograms:[],
            universityDiscipline: [],
            selectedDiscipline: [],
            universityIntakes: [],
            selectedIntakes: [],
            searchTerm: "",
            searchResults:[],
            MangeShortListModel: false,
            shortListData:{
                attributes: '',
                university_name:'',
                program_name:'',
                location:'',
              },
            studentList:[],
            selectedStudent:[],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        await this.getFormApprovalProfile()
        await this.getUnversityDetails()
        await this.getUniversityCourseLists()
        await this.getUniversityProgramLists()
        await this.getUniversityDisciplineLists()
        await this.getUniversityIntakesLists()
        await this.fetchAllStudents();
        // Customizable Area End
        super.componentDidMount();
        this.getToken();

    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };


    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    navigationToAnyPage = (pageName: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);
        const messageId = message.id;
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        const responseCallbacks = {
            [this.getProfileAPICallId]: this.getProfileFormSuccessCallBack,
            [this.getInstituteAPICallId]: this.getUniversityDetailsSuccessCallBack,
            [this.getUniversityCourseListAPICallId]: this.getUniversityCoursesSuccessCallBack,
            [this.getUniversityProgramsAPICallId]: this.getUniversityProgramSuccessCallBack,
            [this.getUniversityDisciplineAPICallId]: this.getUniversityDisciplineSuccessCallBack,
            [this.getUniversityIntakesAPICallId]: this.getUniversityIntakesSuccessCallBack,
            [this.fetchAllStudentsAPICallId]: this.handleStudentResponse,
            [this.uploadCourseAPICallId]: this.handleCourseList,
            [this.getUniversityFilterAPICallId]: this.handleSearchData,
        };

        if (getName(MessageEnum.RestAPIResponceMessage) === messageId) {
            const callback = responseCallbacks[responseData];
            if (callback) {
                callback(message);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    apiCall = async (apiData: any) => {
        const { contentType, method, endPoint, body, type } = apiData;

        let token = await getStorageData("token");

        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue });
    };

    changeSlide = (n: number) => {
        const newIndex = (this.state.slideIndex + n + this.state.images.length) % this.state.images.length;
        this.setState({ slideIndex: newIndex });
    };

    showSlide = (index: number) => {
        this.setState({ slideIndex: index });
    };

    getFormApprovalProfile = async () => {
        this.setState({urlUniversityName: this.props.navigation.getParam("instituteName"), urlUniversityID:this.props.navigation.getParam("instituteID")})
        let userDetails = await getStorageData("userDetails")
        this.getProfileAPICallId = await this.apiCall({
          contentType: "application/json",
          method: 'GET',
          endPoint: configJSON.getProfileEndPoint+userDetails
        });
      }

      getProfileFormSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ userDetails: responseJson.data });
      };

      getUnversityDetails = async () => {
        const apiEndPoint = `${configJSON.getUniversityDetails}?university_name=${this.state.urlUniversityName}&course_id=${this.state.urlUniversityID}`
        this.getInstituteAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: apiEndPoint
        })
    }

    getUniversityDetailsSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const data: UniversityData = responseJson.data[0].attributes;
        this.setState({
            workflow: data.workflow,
            overview: data.overview,
            eligibility: data.eligibility,
            interviewPrepMaterial: data.interview_prep_material,
            images: data.images,
            documentsAndTemplates: data.documents_and_templates,
            locations: data.locations,
            university_name: data.university_name,
            logo: {
                id: data.logo.id,
                filename: data.logo.filename,
                url: data.logo.url,
                type: data.logo.type,
            },
            uni_location: data.uni_location,
            uni_country: data.uni_country,
            ranking: data.ranking,
            institution_type: data.institution_type,
            sponsor_license_number: data.sponsor_license_number,
            offer_tat: data.offer_tat,
            application_fees: data.application_fees,
            post_study_work_permit: data.post_study_work_permit,
            conditional_offer_letter: data.conditional_offer_letter,
            additional_services: data.additional_services
        });
    }

    getUniversityCourseLists = async () => {
        const apiEndPoint = `${configJSON.getUniversityCourseList}?university_name=${this.state.urlUniversityName}`
        this.getUniversityCourseListAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: apiEndPoint
        })
    }

    getUniversityCoursesSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ universityCourses: responseJson.data });
    }

    getUniversityProgramLists = async () => {
        const apiEndPoint = `${configJSON.getUniversityPrograms}?university_name=${this.state.urlUniversityName}`
        this.getUniversityProgramsAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: apiEndPoint
        })
    }

    getUniversityProgramSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ universityPrograms: responseJson.data });
    }

    getUniversityDisciplineLists = async () => {
        const apiEndPoint = `${configJSON.getUniversityDiscipline}?university_name=${this.state.urlUniversityName}`
        this.getUniversityDisciplineAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: apiEndPoint
        })
    }

    getUniversityDisciplineSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ universityDiscipline: responseJson.data });
    }

    getUniversityIntakesLists = async () => {
        const apiEndPoint = `${configJSON.getUniversityIntakes}?university_name=${this.state.urlUniversityName}`
        this.getUniversityIntakesAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: apiEndPoint
        })
    }

    getUniversityIntakesSuccessCallBack = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ universityIntakes: responseJson.data });
    }

    handleProgramChange = (
        event: React.SyntheticEvent,
        value: string[]
    ) => {
        this.setState({ selectedPrograms: value }, () => {
            this.handleFilter()
        });
    };

    handleDisciplineChange = (
        event: React.SyntheticEvent,
        value: string[]
    ) => {
        this.setState({ selectedDiscipline: value }, () => {
            this.handleFilter()
        });
    };

    handleIntakeChange = (
        event: React.SyntheticEvent,
        value: string[]
    ) => {
        this.setState({ selectedIntakes: value }, () => {
            this.handleFilter()
        });
    };

    handleRemovePrograms = (item: string) => {
        this.setState((prevState) => ({
            selectedPrograms: prevState.selectedPrograms.filter(
                (option) => option !== item
            ),
        }), () => {
            this.handleFilter()
        });
    }

    handleRemoveDiscipline = (item: string) => {
        this.setState((prevState) => ({
            selectedDiscipline: prevState.selectedDiscipline.filter(
                (option) => option !== item
            ),
        }), () => {
            this.handleFilter()
        });
    }

    handleRemoveIntakes = (item: string) => {
        this.setState((prevState) => ({
            selectedIntakes: prevState.selectedIntakes.filter(
                (option) => option !== item
            ),
        }), () => {
            this.handleFilter()
        });
    }

    handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { universityCourses } = this.state;
        const { value } = event.target;
        this.setState({ searchTerm: event.target.value });
        const searchResults = universityCourses.filter((item) => {
            const programName = item.attributes.program_name.toLowerCase();
            const universityName =
                item.attributes.university.university_name.toLowerCase();
            const location = item.attributes.university.location.toLowerCase();
            const programLevel = item.attributes.program_level.toLowerCase();
            const discipline = item.attributes.discipline.toLowerCase();
            const country = item.attributes.university.country.toLowerCase();

            return (
                programName.includes(value.toLowerCase()) ||
                universityName.includes(value.toLowerCase()) ||
                location.includes(value.toLowerCase()) ||
                programLevel.includes(value.toLowerCase()) ||
                discipline.includes(value.toLowerCase()) ||
                country.includes(value.toLowerCase())
            );
        });
        this.setState({ searchResults: searchResults });
    };

    handleFilter = async () => {
       const apiEndPoint = `${configJSON.getUniversityFilter}?university_name=${this.state.urlUniversityName}&program_level=${encodeURIComponent(JSON.stringify(this.state.selectedPrograms))}&discipline=${encodeURIComponent(JSON.stringify(this.state.selectedDiscipline))}&intake=${encodeURIComponent(JSON.stringify(this.state.selectedIntakes))}` 
       this.getUniversityFilterAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: apiEndPoint
        })
    }

    shortListModal = (programs: any) => {
        this.setState({
            MangeShortListModel: true,
            shortListData: programs,
        });
    };

    OpenCloseMangeStaff = () => {
        this.setState({
            MangeShortListModel: !this.state.MangeShortListModel,
            shortListData: {
                attributes: '',
                university_name: '',
                program_name: '',
                location: '',
            },
            selectedStudent: []
        })
    }

    fetchAllStudents = async () => {
        this.fetchAllStudentsAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.selectStudent
        });
    };

    handleStudentResponse = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (responseJson && responseJson.data) {
            this.setState({ studentList: responseJson.data });
        }
    }

    handleStudentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedStudentId = event.target.value as string;
        const selectedStudent = this.state.studentList.find(
            (student: any) => student.id === selectedStudentId
        );

        this.setState({ selectedStudent });
    };

    uploadShortlist = async () => {
        const { selectedStudent, shortListData } = this.state;
        const courseId = JSON.stringify([Number(shortListData.id)]);

        const payload = {
            id: String(selectedStudent?.id || ""),
            course_id: courseId,
            student_id: String(selectedStudent?.student_id || "")
        };

        this.uploadCourseAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'POST',
            endPoint: configJSON.uploadStudent,
            body: payload
        });
    };

    handleCourseList = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson && responseJson.message) {
            this.setState({
                MangeShortListModel:false,
                selectedStudent: []
            }, () => toast.success(responseJson.message))
        }
        else {
            toast.error(responseJson.error)
        }
    }

    handleSearchData = (message: Message) => {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({ universityCourses: responseJson.data });
    }
    // Customizable Area End
}
