// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import 'react-toastify/dist/ReactToastify.css';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Cfintegratewhatsapp2 from "../../blocks/cfintegratewhatsapp2/src/Cfintegratewhatsapp2";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue.web" ;
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import ContactList from "../../blocks/contactlist/src/ContactList";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow.web"
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import SetPassword from "../../blocks/forgot-password/src/NewPassword.web"
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web"
import EmailVerification from "../../blocks/forgot-password/src/ForgotPasswordEmail.web"
import EmailAccountLoginBlockWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ContactusWeb from "../../blocks/contactus/src/Contactus.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import TermsConditionsWeb from "../../blocks/termsconditions/src/TermsConditions.web";
import PrivacyPolicyWeb from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import LeadManagement from "../../blocks/leadmanagement/src/LeadManagement.web";
import ViewProfile from "../../blocks/leadmanagement/src/ViewProfile.web";
import RequestManagementWeb from "../../blocks/requestmanagement/src/RequestManagement.web";
import RequestManagementTable from "../../blocks/requestmanagement/src/RequestManagementTable.web";
import DashBoard from "../../blocks/dashboard/src/Dashboard.web";
import LeadManagementApplicationForm from "../../blocks/leadmanagement/src/LeadManagementApplicationForm.web";
import MultipageForms from "../../blocks/multipageforms/src/MultipageForms.web";
import DashboardOffer from "../../blocks/dashboard/src/DashboardOffer.web";
import OtherServices from "../../blocks/dashboard/src/DashboardOtherService.web";
import DasbordViewMore from "../../blocks/dashboard/src/DasbordViewMore.web";
import Applications from "../../blocks/leadmanagement/src/Applications.web";
import Institution from "../../blocks/catalogue/src/Institution.web"
import ViewApplication from "../../blocks/leadmanagement/src/ViewApplications.web";
const routeMap = {
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
  component: ForgotPasswordWeb,
path:"/forgot_password"},
EmailVerification:{
  component: EmailVerification,
path:"/EmailVerification"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Cfintegratewhatsapp2:{
 component:Cfintegratewhatsapp2,
path:"/Cfintegratewhatsapp2"},
TermsConditionsWeb:{
 component:TermsConditionsWeb,
path:"/TermsConditions"},
PrivacyPolicyWeb:{
  component:PrivacyPolicyWeb,
 path:"/PrivacyPolicy"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
ContactList:{
 component:ContactList,
path:"/ContactList"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:ContactusWeb,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
  component: EmailAccountLoginBlockWeb,
path:"/EmailAccountLogin"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
FormApprovalWorkflow:{
  component:FormApprovalWorkflow,
 path:"/FormApprovalWorkflow"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
DashBoard:{
  component:DashBoard,
 path:"/DashBoard"},
  DashboardOffer: {
    component: DashboardOffer,
    path: "/offers"
  },
  OtherServices: {
    component: OtherServices,
    path: "/otherServices"
  },
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
RequestManagementWeb:{
 component:RequestManagementWeb,
path:"/RequestManagement"},
RequestManagementTableWeb:{
  component:RequestManagementTable,
 path:"/RequestManagementTable"},
AddApplication:{
  component:LeadManagementApplicationForm,
 path:"/add-application"},
 EditApplication:{
  component:LeadManagementApplicationForm,
 path:"/edit-application"},
 MultipageForms:{
  component:MultipageForms,
 path:"/MultipageForms"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LeadManagement:{
  component:LeadManagement,
  path: "/LeadManagement"},
  DasbordViewMore: {
    component: DasbordViewMore,
    path: "/DasbordViewMore"
  },
  ViewProfile: {
    component: ViewProfile,
    path: "/ViewProfile"
  },
  ViewApplication: {
    component: ViewApplication,
    path: "/ViewApplication"
  },
  Applications: {
    component: Applications,
    path: "/Applications"
  },
  Institution: {
    component: Institution,
    path: "/Institution/:instituteID/:instituteName"
  },
  // ViewProfile: {
  //   component: ViewProfile,
  //   path: "/ViewProfile/:id"
  // },
  

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  SetPassword: {
    component: SetPassword,
    path: '/set_password'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View >
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;