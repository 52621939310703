// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Country, State } from "country-state-city";
import {
  CountryInterfaceData
} from "../../../../packages/blocks/contactus/src/ContactusController";
import * as Yup from "yup";
import { SelectEventType } from "./LeadManagementApplicationFormController.web";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  token: null | string;
  toggleDrawer: boolean;
  userToken: string,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: { url: string },
    },
  },
  CardDetailsData: Array<{
    id: string | number, heading: string,
    points: string, imageUrl: string,
    PublishDate: string, AnnouncementType: string,
    ContryName: Array<string>
  }>,
  page: number,
  valueKey: string;
  updateModalOpen: boolean,
  updateValue: string,
  startDate: null | Date,
  endDate: null | Date,
  formattedStartDate: string,
  formattedEndDate: string,
  StundetData: {
    id: string,
    type: string;
    student_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    country: string;
    country_code: string | null;
    marital_status: string;
    state: string;
    emergency_contact_email: string,
    city: string;
    mobile: string;
    email: string;
    emergency_contact_person: string;
    emergency_contact_mobile: string;
    current_status: string;
    passport_number: string;
    associate_branch_name: string;
    created_at: string;
    updated_at: string;
    agent_name: string;
    agent_id: string;
    travel_history: Array<{ date: string, country: string }>;
    visa_refusal: Array<{ date: string, country: string }>;
    documents: {
      data: Array<{
        Name: string, Id: string,
        FileType: string, DocumentCategory: string,
        AccountId: string, Comment: string,
        Status: string, FileUrl: string, FileSize: string
      }>
    };
    image: string | null;
    country_of_nationality: string,
    country_of_residence: string,
  },
  IsFormEdit: boolean,
  AssociateName: Array<{ id: number, name: string }>,
  EditFormData: {
    FirstName: string,
    LastName: string,
    Gender: string,
    MaritalStatus: string,
    Countryofnationality: string,
    Countryofresidence: string,
    State: string,
    City: string,
    PassportNumber: string,
    Mobile: string,
    Email: string,
    AssociatedBranch: string,
    EmergencyContactPerson: string,
    EmergencyMobile: string,
    EmergencyEmail: string,
    UserImage: string | null,
    AddVisaRefusal: Array<{ country: string, date: string }>,
    TravelHistory: Array<{ country: string, date: string }>,
  },
  FormikFormReinitialize: boolean,
  countryData: Array<CountryInterfaceData>;
  countryCodes: Array<{ alpha2: string; name: string; country_code: string; }>;
  contryCodeValue: string,
  emercontryCodeValue: string;
  stateData: Array<{ name: string }>;
  passportErrorMessage: string;
  isPassportErrorValid: boolean;
  IsStudentMobileValid: boolean;
  IsStudentMobileText: string;
  IsEmergencyMobileValid: boolean;
  IsEmergencyMobileText: string;
  ActiveTabIndex: number;
  IsUploadDoumentModal: boolean;
  UploadDocumentAPIData: {
    Title: string,
    SubTitlt: string,
    APIKey: string,
  },
  UploadFIle: File[],
  FileSize: string[],
  UploadErrorText: string[],
  RemarkText: string,
  IsProfilePictureUpdate: boolean,
  ProfilePictureFile: File[],
  ShortListApiResponseData: Array<{
    Id: string,
    Type: string,
    ProgramName: string,
    ProgramLink: string,
    ProgramLevel: string,
    IsShortlisted: boolean,
    Discipline: string,
    FeesAfterScholarship: string,
    Fees: string,
    Duration: string
    CashDeposit: string
    UniversityId: string,
    UniversityName: string,
    UniversityLocation: string,
    UniversityProvince: string,
    UniversityYear: string,
    UniversityCountry: string,
    UniversityLogo: string,
    UniversityLogoFileName: string,
    UniversityOfferTat: string,
    Sessions: Array<{
      IntakeLabel: string,
      Availability: string,
      Month: Array<string>
    }>
  }>,
  VisaAndTravelData: Array<{
    date: string,
    country: string,
  }>,
  IsVisaAndTravelModelOpen: boolean,
  VisaAndTravelTitle: string,
  AskForExpertModel: boolean,
  VisionStaffData: Array<{
    id: string;
    StaffType: string;
    FirstName: string;
    LastName: string;
    phone_number: string;
    email: string;
    status: string;
    image: string;
    Role: string
  }>,
  UniversityStaff: Array<{
    id: string;
    StaffType: string;
    email: string;
    FirstName: string;
    image: string;
    LastName: string;
    PhoneNumber: string;
    role: string;
    status: string;
    UniversityName: string;
  }>,
  AgentStaff: Array<{
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Designation: string,
    application_id: string;
    student_id: string;
    generated_student_id: string;
    agent_id: string;
    assignee_id: string;
    application_status: string;
    application_stage: ApplicationStage;
    course: string;
    university: string;
    agent_email: string;
    applied_by: string;
    start_date: string;
  }>,
  ApplicationDataList: Array<{
    id: string,
    Type: string,
    ApplicationId: string,
    StudentId: string,
    GeneratedStudentId: string,
    AgentId: string,
    AssigneeId: string,
    ApplicationStatus: string,
    ApplicationStageForInprogress: {
      application_fees_paid: boolean;
      application_received: boolean;
      cas_loa_applied: boolean;
      conditional_offer: boolean;
      pre_cas_loa_stage: boolean;
      submitted_to_institution: boolean;
      tuition_fees_paid: boolean;
      unconditional_offer: boolean;
      visa_applied: boolean;
      visa_received: boolean;
      cas_loa_received: boolean
    },
    ApplicationStageForRefund: {
      refund_initiated?: boolean,
      refund_received?: boolean,
      refund_rejected?: boolean,
      refund_submitted_to_institution?: boolean
    }
    course: string,
    University: string,
    AgentEmail: string,
    AppliedBy: string,
    StartDate: string
  }>
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start
interface ApplicationStage {
  application_fees_paid: boolean;
  application_received: boolean;
  submitted_to_institution: boolean;
  conditional_offer: boolean;
  unconditional_offer: boolean;
  tuition_fees_paid: boolean;
  pre_cas_loa_stage: boolean;
  cas_loa_applied: boolean;
  visa_applied: boolean;
  visa_received: boolean;
}
interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: { url: string };
  };
}

interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export const configJSON = require("./config");

export interface StudentAPITypes {
  student: {
    data: {
      id: string;
      type: string;
      attributes: {
        student_id: string;
        first_name: string;
        last_name: string;
        gender: string;
        country: string;
        country_code: string | null;
        marital_status: string;
        country_of_nationality: string,
        country_of_residence: string,
        state: string;
        city: string;
        mobile: string;
        email: string;
        emergency_contact_person: string;
        emergency_contact_mobile: string;
        current_status: string;
        passport_number: string;
        associate_branch_name: string;
        created_at: string;
        updated_at: string;
        agent_name: string;
        emergency_contact_email: string,
        agent_id: string;
        emergency_country_code: string,
        travel_history: {
          data: Array<
            {
              id: string,
              type: string,
              attributes: {
                date: string,
                country: string
              }
            }
          >
        };
        visa_refusal: {
          data: Array<
            {
              id: string,
              type: string,
              attributes: {
                date: string,
                country: string
              }
            }
          >
        };
        documents: {
          data: Array<{
            id: string,
            type: string,
            attributes: {
              id: number,
              name: string,
              file_type: string,
              document_category: string,
              account_id: string,
              created_at: string,
              updated_at: string,
              comment: string,
              status: string,
              url: {
                url: string
              },
              file_size: string
            }
          }>
        };
        image: {
          filename: string,
          id: number,
          type: string,
          url: string
        } | null;
      }
    }
  }
}
interface UniversityLogo {
  id: number;
  filename: string;
  url: string;
  type: string;
}

interface University {
  id: number;
  university_name: string;
  location: string;
  province: string;
  country: string;
  associated_tags: string;
  offer_tat: string;
  year: number;
  logo: UniversityLogo;
}

interface Session {
  intake_label: string;
  months: string[];
  availability: string;
}

interface ProgramAttributes {
  program_name: string;
  program_level: string;
  duration: string;
  fees: string;
  fees_after_scholarship: string;
  cas_deposit: string;
  program_link: string;
  discipline: string;
  is_shortlisted: boolean;
  university: University;
  sessions: Session[];
}

interface ProgramData {
  id: string;
  type: string;
  attributes: ProgramAttributes;
}

interface ShortListApiResponse {
  data: ProgramData[];
}

interface AssignedStaffInfoResponse {
  data: AssignedStaffInfo[];
  meta: Meta;
}

interface AssignedStaffInfo {
  type: string;
  attributes: {
    university_staff_info: UniversityStaffInfo[];
    sales_manager_info: SalesManagerInfo;
    agent_staff_info: AgentStaffInfo[];
  };
}

interface UniversityStaffInfo {
  id: string;
  type: string;
  attributes: UniversityStaffAttributes;
}

interface UniversityStaffAttributes {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  university_name: string;
  status: string;
  role: string;
  image: string;
}

interface SalesManagerInfo {
  id: string;
  type: string;
  attributes: SalesManagerAttributes;
}

interface SalesManagerAttributes {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  status: string;
  image: string;
  role: string
}

interface AgentStaffInfo {
  id: string;
  type: string;
  attributes: AgentStaffAttributes;
}

interface AgentInfo {
  agent_id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  percentage: string;
  email: string;
  designation: string;
  status: string;
  no_of_employees: string;
  full_phone_number: string,
  marriage_anniversary: string,
  country_code: string;
  phone_number: string;
  country: string;
  state: string,
  city: string,
  verification_type: string,
  activated: boolean;
  is_logged_in: boolean;
  gender: string,
  profile_status: string,
  date_of_birth: string,
  preferred_notification: Record<string, unknown>;
  allow_all: boolean;
  updated_at: string;
  marital_status: string;
  allow_navigate: boolean;
  created_at: string;
  last_logged_in: string;
  role: string
  image: string;
  log: {
    data: [];
  };
}
interface AgentStaffAttributes {
  application_id: string;
  student_id: string;
  generated_student_id: string;
  agent_id: string;
  assignee_id: string;
  application_status: string;
  application_stage: ApplicationStage;
  course: string;
  university: string;
  agent_email: string;
  applied_by: string;
  start_date: string;
  agent: AgentInfo
}

interface ApplicationStage {
  application_fees_paid: boolean;
  application_received: boolean;
  submitted_to_institution: boolean;
  conditional_offer: boolean;
  unconditional_offer: boolean;
  tuition_fees_paid: boolean;
  pre_cas_loa_stage: boolean;
  cas_loa_applied: boolean;
  visa_applied: boolean;
  visa_received: boolean;
}

interface Meta {
  message: string;
}


// Define the structure for the overall response

interface ApplicationDataListType {
  data: StudentApplication[];
}

interface StudentApplication {
  id: string;
  type: string;
  attributes: ApplicationAttributes;
}

interface ApplicationAttributes {
  application_id: string;
  student_id: number;
  generated_student_id: string;
  agent_id: number;
  assignee_id: number;
  application_status: string;
  application_stage: {
    application_fees_paid: boolean;
    application_received: boolean;
    cas_loa_applied: boolean;
    conditional_offer: boolean;
    pre_cas_loa_stage: boolean;
    submitted_to_institution: boolean;
    tuition_fees_paid: boolean;
    unconditional_offer: boolean;
    visa_applied: boolean;
    visa_received: boolean;
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
    cas_loa_received: boolean
  };
  course: string;
  university: string;
  agent_email: string;
  applied_by: string;
  start_date: string;
}

// Customizable Area End

export default class ViewProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  GetStudentsAPICallId: string = "";
  getAssociateBranchNamesAPICallID: string = "";
  getCountryCodeAPICallID: string = "";
  validatePassportAPICallID: string = "";
  validateStudentMobileNoAPICallID: string = "";
  validateEmergencyMobileNoAPICallID: string = "";
  StudentUpDateAPICall: string = "";
  DeleteDoumentApiCallId: string = "";
  UploadDocumentForStudentAPICallId: string = "";
  GetShortListAPICallId: string = "";
  GetAssignStaffCallId: string = "";
  ApplyNowShortlistButtonApiCallId: string = "";
  getAplicationDataListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      loading: true,
      token: null,
      toggleDrawer: false,
      userToken: '',
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
      CardDetailsData: [],
      page: 1,
      valueKey: '',
      updateModalOpen: false,
      updateValue: '',
      startDate: null,
      endDate: null,
      formattedStartDate: "",
      formattedEndDate: "",
      StundetData: {
        id: '',
        type: '',
        student_id: "",
        first_name: "",
        last_name: "",
        gender: "",
        country: "",
        country_code: "",
        marital_status: "",
        state: "",
        city: "",
        mobile: "",
        email: "",
        emergency_contact_person: "",
        emergency_contact_mobile: "",
        current_status: "",
        passport_number: "",
        associate_branch_name: "",
        created_at: "",
        updated_at: "",
        agent_name: "",
        agent_id: "",
        travel_history: [],
        visa_refusal: [],
        documents: { data: [] },
        image: "",
        country_of_nationality: "",
        country_of_residence: "",
        emergency_contact_email: ""
      },
      IsFormEdit: false,
      AssociateName: [],
      countryCodes: [],
      contryCodeValue: "",
      emercontryCodeValue: "",
      EditFormData: {
        FirstName: "",
        LastName: "",
        Gender: "",
        MaritalStatus: "",
        Countryofnationality: "",
        Countryofresidence: '',
        State: "",
        City: "",
        PassportNumber: "",
        Mobile: "",
        Email: "",
        AssociatedBranch: "",
        EmergencyContactPerson: "",
        EmergencyMobile: "",
        EmergencyEmail: "",
        UserImage: "",
        AddVisaRefusal: [{
          country: "",
          date: ""
        }],
        TravelHistory: [{
          country: "",
          date: ""
        }]
      },
      FormikFormReinitialize: false,
      countryData: Country.getAllCountries() as never,
      stateData: [],
      passportErrorMessage: "",
      isPassportErrorValid: false,
      IsStudentMobileValid: false,
      IsStudentMobileText: "",
      IsEmergencyMobileValid: false,
      IsEmergencyMobileText: "",
      ActiveTabIndex: 0,
      IsUploadDoumentModal: false,
      UploadDocumentAPIData: {
        Title: "",
        SubTitlt: "",
        APIKey: "",
      },
      UploadFIle: [],
      FileSize: [],
      UploadErrorText: [],
      RemarkText: "",
      IsProfilePictureUpdate: false,
      ProfilePictureFile: [],
      ShortListApiResponseData: [],
      VisaAndTravelData: [],
      IsVisaAndTravelModelOpen: false,
      VisaAndTravelTitle: "",
      AskForExpertModel: false,
      VisionStaffData: [],
      UniversityStaff: [],
      AgentStaff: [],
      ApplicationDataList: []
    };

    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.GetAPICallFuncation(apiRequestCallId, responseJson)
        this.getAssosiateAPICall(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.getCountryCodeAPICallID) {
          this.setCountryCodes(responseJson)
        }
        if (apiRequestCallId === this.validatePassportAPICallID) {
          this.setPassportValidation(responseJson)
        }
        this.StudenAPiCallRecied(apiRequestCallId, responseJson)
        this.ErroMobileAPICall(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.StudentUpDateAPICall) {
          this.FormSubmitAPICall(responseJson)
        }
        this.DeleteAPICallFuncationResponseSet(apiRequestCallId, responseJson)
        this.UploadDocumentAPICallResponse(apiRequestCallId, responseJson)
        this.ShortListApiCallResponseSet(apiRequestCallId, responseJson)
        this.GetAssignedStaffAPICallResponse(apiRequestCallId, responseJson)
        this.ApplyNowPostApiResponse(apiRequestCallId, responseJson)
        this.GetAplicationDataListApiResponse(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  GetAplicationDataListApiResponse = (apiRequestCallId: string, responseJson: ApplicationDataListType) => {
    if (apiRequestCallId === this.getAplicationDataListApiCallId) {
      const ApiResponse = responseJson.data.map((items) => {
        const InnterAttributes = items.attributes
        const ApplicationStageInnerData = InnterAttributes.application_stage
        return {
          id: items.id,
          Type: items.type,
          ApplicationId: InnterAttributes.application_id,
          StudentId: `${InnterAttributes.student_id}`,
          GeneratedStudentId: InnterAttributes.generated_student_id,
          AgentId: `${InnterAttributes.agent_id}`,
          AssigneeId: `${InnterAttributes.assignee_id}`,
          ApplicationStatus: InnterAttributes.application_status,
          ApplicationStageForInprogress: {
            application_fees_paid: ApplicationStageInnerData.application_fees_paid,
            application_received: ApplicationStageInnerData.application_received,
            cas_loa_applied: ApplicationStageInnerData.cas_loa_applied,
            cas_loa_received: ApplicationStageInnerData.cas_loa_received,
            conditional_offer: ApplicationStageInnerData.conditional_offer,
            pre_cas_loa_stage: ApplicationStageInnerData.pre_cas_loa_stage,
            submitted_to_institution: ApplicationStageInnerData.submitted_to_institution,
            tuition_fees_paid: ApplicationStageInnerData.tuition_fees_paid,
            unconditional_offer: ApplicationStageInnerData.unconditional_offer,
            visa_applied: ApplicationStageInnerData.visa_applied,
            visa_received: ApplicationStageInnerData.visa_received,
          },
          ApplicationStageForRefund: {
            refund_initiated: ApplicationStageInnerData.refund_initiated,
            refund_received: ApplicationStageInnerData.refund_received,
            refund_rejected: ApplicationStageInnerData.refund_rejected,
            refund_submitted_to_institution: ApplicationStageInnerData.refund_submitted_to_institution
          },
          course: InnterAttributes.course,
          University: InnterAttributes.university,
          AgentEmail: InnterAttributes.agent_email,
          AppliedBy: InnterAttributes.applied_by,
          StartDate: InnterAttributes.start_date
        }
      })

      this.setState({
        ApplicationDataList: ApiResponse,
        loading: false
      })
    }
  }

  SuccessStperCount = (data: {
    application_fees_paid: boolean;
    application_received: boolean;
    cas_loa_applied: boolean;
    conditional_offer: boolean;
    pre_cas_loa_stage: boolean;
    submitted_to_institution: boolean;
    tuition_fees_paid: boolean;
    unconditional_offer: boolean;
    visa_applied: boolean;
    visa_received: boolean;
    cas_loa_received: boolean
  }) => {
    let SuccessCount = -1; // Start with an initial value of -1
    const {
      application_fees_paid,
      application_received,
      cas_loa_applied,
      conditional_offer,
      pre_cas_loa_stage,
      submitted_to_institution,
      tuition_fees_paid,
      unconditional_offer,
      visa_applied,
      visa_received,
      cas_loa_received
    } = data;

    if (application_fees_paid) {
      SuccessCount += 1;
    }
    if (application_received) {
      SuccessCount += 1;
    }
    if (cas_loa_applied) {
      SuccessCount += 1;
    }
    if (cas_loa_received) {
      SuccessCount += 1;
    }
    if (conditional_offer) {
      SuccessCount += 1;
    }
    if (pre_cas_loa_stage) {
      SuccessCount += 1;
    }
    if (submitted_to_institution) {
      SuccessCount += 1;
    }
    if (tuition_fees_paid) {
      SuccessCount += 1;
    }
    if (unconditional_offer) {
      SuccessCount += 1;
    }
    if (visa_applied) {
      SuccessCount += 1;
    }
    if (visa_received) {
      SuccessCount += 1;
    }

    return SuccessCount;

  }

  RefundStperCount = (data: {
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
  }) => {
    let RefundCount = -1;
    const { refund_initiated, refund_received, refund_rejected, refund_submitted_to_institution } = data
    if (refund_initiated) {
      RefundCount += 1
    }
    if (refund_submitted_to_institution) {
      RefundCount += 1
    }
    if (refund_received) {
      RefundCount += 1
    }
    if (refund_rejected) {
      RefundCount += 1
    }
    return RefundCount
  }

  ApplyNowPostApiResponse = (apiRequestCallId: string, responseJson: { errors: Array<string> }) => {
    if (apiRequestCallId === this.ApplyNowShortlistButtonApiCallId) {
      if (responseJson.errors) {
        if (Array.isArray(responseJson.errors)) {
          toast.warning(responseJson.errors[0])
        }
      } else {
        toast.success("Application applyed successfully")
        this.setState({
          loading: false
        })
        this.GetAplicationData()
        this.GetSortListApiData()
      }
    }
  }

  ShortListApiCallResponseSet = (apiRequestCallId: string, responseJson: ShortListApiResponse) => {
    // GetShortListAPICallId
    if (apiRequestCallId === this.GetShortListAPICallId) {
      const ApiResponse = responseJson.data.map((item) => {
        return {
          Id: `${item.id}`,
          Type: item.type,
          ProgramName: item.attributes.program_name,
          ProgramLink: item.attributes.program_link,
          ProgramLevel: item.attributes.program_level,
          IsShortlisted: item.attributes.is_shortlisted,
          Discipline: item.attributes.discipline,
          FeesAfterScholarship: item.attributes.fees_after_scholarship,
          Fees: item.attributes.fees,
          Duration: item.attributes.duration,
          CashDeposit: item.attributes.cas_deposit,
          UniversityId: `${item.attributes.university.id}`,
          UniversityName: item.attributes.university.university_name,
          UniversityLocation: item.attributes.university.location,
          UniversityProvince: item.attributes.university.province,
          UniversityYear: `${item.attributes.university.year}`,
          UniversityCountry: item.attributes.university.country,
          UniversityLogo: item.attributes.university.logo.url,
          UniversityLogoFileName: item.attributes.university.logo.filename,
          UniversityOfferTat: item.attributes.university.offer_tat,
          Sessions: item.attributes.sessions.map((SessionData) => {
            return {
              IntakeLabel: SessionData.intake_label,
              Availability: SessionData.availability,
              Month: SessionData.months.map((MonthData) => MonthData)
            }
          })
        }
      })
      this.setState({
        ShortListApiResponseData: ApiResponse
      })
    }
  }

  UploadDocumentAPICallResponse = async (apiRequestCallId: string, responseJson: { success: string, error?: Array<string> }) => {
    if (apiRequestCallId === this.UploadDocumentForStudentAPICallId) {
      if (responseJson.success === "Documents uploaded successfully") {
        const id = await getStorageData("StudentId")
        this.GetStudentsData(id)
        this.CloseUploadModel()
        toast.success(responseJson.success)
      } else {
        if (responseJson.error) {
          this.CloseUploadModel()
          this.setState({
            loading: false
          })
          toast.error(responseJson.error[0])
          this.ErrorToastHander({ error: [{ message: responseJson.error[0] }] })
        }
      }
    }
  }

  DeleteAPICallFuncationResponseSet = async (apiRequestCallId: string, responseJson: { message: string }) => {
    if (apiRequestCallId === this.DeleteDoumentApiCallId) {
      if (responseJson.message === "Document deleted successfully") {
        toast.success(responseJson.message)
        const id = await getStorageData("StudentId")
        this.GetStudentsData(id)
      }
    }
  }

  GetAPICallFuncation = (apiRequestCallId: string, responseJson: UserDetailsResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.getProfileFormSuccessCallBack(responseJson)
    }
  }

  getAssosiateAPICall = (apiRequestCallId: string, responseJson: {
    data:
    Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string;
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  }) => {
    if (apiRequestCallId === this.getAssociateBranchNamesAPICallID) {
      this.setAssociateNameResponse(responseJson)
    }
  }

  FormSubmitAPICall = async (responseJson: { [key: string]: Array<{ [key: string]: string }> }) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        IsFormEdit: false,
        IsProfilePictureUpdate: false,
        ProfilePictureFile: []
      })
      const id = await getStorageData("StudentId")
      this.GetStudentsData(id)
      toast.success("Update Successfully")
    } else {
      this.ErrorToastHander(responseJson)
    }
  }
  ErrorToastHander = (responseJson: { [key: string]: Array<{ [key: string]: string }> }) => {
    if (Array.isArray(responseJson.error)) {
      toast.error(responseJson.error[0])
    } else {
      toast.error(Object.values(responseJson.error)[0] as string)
    }
  }


  ErroMobileAPICall = (apiRequestCallId: string, responseJson: { errors: Array<{ message: string }>, message: string }) => {
    if (apiRequestCallId === this.validateStudentMobileNoAPICallID) {
      this.setStudentMobile(responseJson)
    }
    if (apiRequestCallId === this.validateEmergencyMobileNoAPICallID) {
      this.setEmergencyMobile(responseJson)
    }
  }

  StudenAPiCallRecied = (apiRequestCallId: string, responseJson: StudentAPITypes) => {
    if (apiRequestCallId === this.GetStudentsAPICallId) {
      this.SetStudentDataAPIcall(responseJson)
    }
  }

  IsItValidValues = (value: string | null | undefined) => {
    if (typeof value === "string") {
      return value
    } else {
      return ""
    }

  }

  SetStudentDataAPIcall = (responseJson: StudentAPITypes) => {
    const AttributesResponse = responseJson.student.data.attributes;

    if (AttributesResponse) {
      const travel_history = AttributesResponse.travel_history.data.map((item) => {
        return {
          date: item.attributes.date,
          country: item.attributes.country
        }
      })
      const visa_refusal = AttributesResponse.visa_refusal.data.map((item) => {
        return {
          date: item.attributes.date,
          country: item.attributes.country
        }
      })
      const DoumentData = AttributesResponse.documents.data?.map((item) => {
        return {
          Name: item.attributes.name,
          Id: `${item.attributes.id}`,
          FileType: item.attributes.file_type,
          DocumentCategory: item.attributes.document_category,
          AccountId: item.attributes.account_id ?? '',
          Comment: item.attributes.comment ?? '',
          Status: item.attributes.status,
          FileUrl: item?.attributes?.url?.url,
          FileSize: item.attributes.file_size
        }
      })

      this.setState({
        StundetData: {
          id: responseJson.student.data.id,
          type: responseJson.student.data.type,
          student_id: AttributesResponse.student_id,
          first_name: AttributesResponse.first_name,
          last_name: AttributesResponse.last_name,
          gender: AttributesResponse.gender,
          country: AttributesResponse.country,
          country_code: AttributesResponse.country_code,
          marital_status: AttributesResponse.marital_status,
          state: AttributesResponse.state,
          city: AttributesResponse.city,
          mobile: AttributesResponse.mobile,
          email: AttributesResponse.email,
          emergency_contact_person: AttributesResponse.emergency_contact_person,
          emergency_contact_mobile: AttributesResponse.emergency_contact_mobile,
          emergency_contact_email: AttributesResponse.emergency_contact_email,
          current_status: AttributesResponse.current_status,
          passport_number: AttributesResponse.passport_number,
          associate_branch_name: AttributesResponse.associate_branch_name,
          created_at: AttributesResponse.created_at,
          updated_at: AttributesResponse.updated_at,
          agent_name: AttributesResponse.agent_name,
          agent_id: AttributesResponse.agent_id,
          travel_history: travel_history,
          visa_refusal: visa_refusal,
          documents: { data: DoumentData },
          image: this.IsItValidValues(AttributesResponse.image?.url),
          country_of_nationality: AttributesResponse.country_of_nationality,
          country_of_residence: AttributesResponse.country_of_residence,
        },
        contryCodeValue: this.IsItValidValues(AttributesResponse.country_code),
        emercontryCodeValue: this.IsItValidValues(AttributesResponse.emergency_country_code),
      }, () => {
        this.GetAplicationData()
        const { StundetData } = this.state
        this.LoderFalseTrue()
        this.getSalesManagerInfoApi()
        this.GetSortListApiData()
        const statedata = State.getAllStates()
        const filteredData = statedata.filter((data: { countryCode: string; }) => {
          return data.countryCode === StundetData.country_of_residence
        });
        this.setState({
          stateData: filteredData
        })
      })
    }

  }

  LoderFalseTrue = () => {
    this.setState({
      loading: !this.state.loading
    })
  }


  async componentDidMount() {
    const token = await getStorageData("token")
    const id = await getStorageData("StudentId")
    this.GetStudentsData(id)
    this.setState({ userToken: token }, () => {
      this.getFormApprovalProfile()
      this.getAssociateBranchNames()
      this.getCountryCode()
    })
  }

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage",
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  ViewProfileApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };


  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }

  GetStudentsData = async (id: string) => {
    this.setState({
      loading: true
    })
    this.GetStudentsAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_dashboard/students/${id}`
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleOpenClose = (value?: string) => {
    this.setState({
      updateModalOpen: !this.state.updateModalOpen,
      updateValue: `${value}`,
    });
  };

  EditFormFuncation = () => {
    const { StundetData } = this.state

    this.setState({
      IsFormEdit: true,
      EditFormData: {
        FirstName: StundetData.first_name,
        LastName: StundetData.last_name,
        Gender: StundetData.gender,
        MaritalStatus: StundetData.marital_status,
        Countryofnationality: StundetData.country_of_nationality,
        Countryofresidence: StundetData.country_of_residence,
        State: StundetData.state,
        City: StundetData.city,
        PassportNumber: StundetData.passport_number,
        Mobile: StundetData.mobile,
        Email: StundetData.email,
        AssociatedBranch: StundetData.associate_branch_name,
        EmergencyContactPerson: StundetData.emergency_contact_person,
        EmergencyMobile: StundetData.emergency_contact_mobile,
        EmergencyEmail: StundetData.emergency_contact_email,
        AddVisaRefusal: StundetData.visa_refusal,
        TravelHistory: StundetData.travel_history,
        UserImage: StundetData.image
      }
    })
  }

  DeleteUploadProfilePic = () => {
    this.setState({
      EditFormData: {
        ...this.state.EditFormData, UserImage: ""
      },
      ProfilePictureFile: [],
      IsProfilePictureUpdate: false
    })
  }

  UploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length !== 0) {
      const acceptedFormats = ['jpeg', 'png', 'jpg']
      const fileExtension = this.getFileExtension(e.target.files[0]);
      if (!acceptedFormats.includes(fileExtension as string)) {
        toast.warning("Please upload a file in .png, .jpg, .jpeg")
      } else {
        this.setState({
          EditFormData: {
            ...this.state.EditFormData, UserImage: URL.createObjectURL(e.target.files[0]) ?? null
          },
          ProfilePictureFile: [e.target.files[0]],
          IsProfilePictureUpdate: true
        })
      }
    }
  }

  StudentUpdateSchema = () => {
    return Yup.object().shape({
      FirstName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed').max(30, 'maximum 30 characters are allowed').required(" First Name is required"),
      LastName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed').max(30, 'maximum 30 characters are allowed').required("Last name is required"),
      Mobile: Yup.string().required("Mobile is required"),
      Email: Yup.string().email("Please enter valid email").required("*Email is required"),
      Gender: Yup.string().required("gender is required"),
      MaritalStatus: Yup.string().required("Marital Status is required"),
      Countryofnationality: Yup.string().required("Country of nationality is required"),
      Countryofresidence: Yup.string().required("Country of residence is required"),
      State: Yup.string().required("State is required"),
      City: Yup.string().required("City is required"),
      PassportNumber: Yup.string().matches(configJSON.passPortRegex, configJSON.passportValidationMsg).required("Passport Number is required"),
      AssociatedBranch: Yup.string().required("Associated Branch is required"),
      EmergencyContactPerson: Yup.string().required("Emergency Person is required"),
      EmergencyMobile: Yup.string().required("Emergency Contact Person is required"),
      EmergencyEmail: Yup.string().email("Please enter valid email").required("Emergency Email is required"),
      AddVisaRefusal: Yup.array().of(
        Yup.object().shape({
          country: Yup.string().notRequired(),
          date: Yup.string().notRequired(),
        })
      ).notRequired(),
      TravelHistory: Yup.array().of(
        Yup.object().shape({
          country: Yup.string().notRequired(),
          date: Yup.string().notRequired(),
        })
      ).notRequired(),
    })
  }

  onChangeOfStudentData = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    SetFieldValue: (field: string,
      value: string, shouldValidate?:
        boolean) => void
  ) => {
    if (e.target.name === "Mobile") {
      this.validateStudentMobile(e.target.value, this.state.contryCodeValue);
      this.setState({ IsStudentMobileText: "", IsStudentMobileValid: false })
    }
    if (e.target.name === "EmergencyMobile") {
      this.validateEmergencyMobile(e.target.value, this.state.emercontryCodeValue);
      this.setState({ IsEmergencyMobileText: "", IsEmergencyMobileValid: false })
    }
    if (e.target.name === "PassportNumber") {
      this.validatePassport(e.target.value)
      this.setState({ passportErrorMessage: "", isPassportErrorValid: false })
    }
    SetFieldValue(e.target.name, e.target.value)
    this.setState({ EditFormData: { ...this.state.EditFormData, [e.target.name]: e.target.value } })
  }

  validateStudentMobile = async (mobileno: string, countrycode: string) => {
    this.validateStudentMobileNoAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.validateMobileNumber}?mobile=${mobileno}&country_code=${countrycode}`
    });
  }

  validateEmergencyMobile = async (mobileno: string, countrycode: string) => {
    this.validateEmergencyMobileNoAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `/bx_block_dashboard/students/emergency_mobile_number?emergency_country_code=${countrycode}&emergency_contact_mobile=${mobileno}`
    });
  }
  setEmergencyMobile = (responseJson: { errors: Array<{ message: string }>, message: string }) => {
    const errorMessage = responseJson.errors?.[0]?.message
    this.setState({ IsEmergencyMobileText: errorMessage, IsEmergencyMobileValid: true })
    if (responseJson.message === "Mobile number is correct.") {
      this.setState({ IsEmergencyMobileText: "", IsEmergencyMobileValid: false })
    }
  }

  setStudentMobile = (responseJson: { errors: Array<{ message: string }>, message: string }) => {
    const errorMessage = responseJson.errors?.[0]?.message
    this.setState({ IsStudentMobileText: errorMessage, IsStudentMobileValid: true })
    if (responseJson.message === "Mobile number is correct.") {
      this.setState({ IsStudentMobileText: "", IsStudentMobileValid: false })
    }
  }

  OnChangeOfStudentDataRadio = (value: string, name: string, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    SetFieldValue(name, value)
    this.setState({ EditFormData: { ...this.state.EditFormData, [name]: value } })
  }

  onChnageAddVisaRefusalContry = (e: SelectEventType, SetFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, index: number, values: Array<{ country: string; date: string; }>) => {
    if (typeof e.target.value === "string") {
      let data = [...values]
      data[index].country = e.target.value
      SetFieldValue(`AddVisaRefusal.${index}.country`, e.target.value)
      this.setState({
        EditFormData: {
          ...this.state.EditFormData, AddVisaRefusal: data
        }
      })
    }
  }

  onChnageAddVisaRefusDate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, SetFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, index: number, values: Array<{ country: string; date: string; }>) => {
    if (typeof e.target.value === "string") {
      let data = [...values]
      data[index].date = e.target.value
      SetFieldValue(`AddVisaRefusal.${index}.date`, e.target.value)
      this.setState({
        EditFormData: {
          ...this.state.EditFormData, AddVisaRefusal: data
        }
      })
    }
  }


  onChnageTravelHistoryContry = (e: SelectEventType, SetFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, index: number, values: Array<{ country: string; date: string; }>) => {
    if (typeof e.target.value === "string") {
      let data = [...values]
      data[index].country = e.target.value
      SetFieldValue(`TravelHistory.${index}.country`, e.target.value)
      this.setState({
        EditFormData: {
          ...this.state.EditFormData, TravelHistory: data
        }
      })
    }
  }

  onChnageTravelHistoryDate = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, SetFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, index: number, values: Array<{ country: string; date: string; }>) => {
    if (typeof e.target.value === "string") {
      let data = [...values]
      data[index].date = e.target.value
      SetFieldValue(`TravelHistory.${index}.date`, e.target.value)
      this.setState({
        EditFormData: {
          ...this.state.EditFormData, TravelHistory: data
        }
      })
    }
  }



  getAssociateBranchNames = async () => {
    this.getAssociateBranchNamesAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAssociateBranchNames
    });
  }
  setAssociateNameResponse = (response: {
    data:
    Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string;
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  }) => {
    const data = response?.data.map((item) => {
      return {
        id: item.attributes.id,
        name: item.attributes.branch_name
      }
    })
    this.setState({
      AssociateName: data
    })
  }

  getCountryCode = async () => {
    this.getCountryCodeAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCountryCode
    });
  }

  setCountryCodes = (responseJson: Array<{
    alpha2: string,
    name: string,
    country_code: string
  }>) => {
    this.setState({ countryCodes: responseJson })
    const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
    this.setState({ contryCodeValue: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}`, emercontryCodeValue: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}` })
  };

  AddVisaRefusal = (values: Array<{ country: string; date: string; }>, SetFieldValue: (field: string, value: Array<{ country: string, date: string }>, shouldValidate?: boolean) => void) => {
    let data = [...values]
    let newdata = data.concat({
      country: '',
      date: ''
    })
    SetFieldValue("AddVisaRefusal", newdata)
    this.setState({
      EditFormData: {
        ...this.state.EditFormData, AddVisaRefusal: newdata
      }
    })
  }

  DeleteAddVisaRefusal = (values: Array<{ country: string; date: string; }>, SetFieldValue: (field: string, value: Array<{ country: string, date: string }>, shouldValidate?: boolean) => void, index: number) => {
    let data = [...values];
    data.splice(index, 1);
    SetFieldValue("AddVisaRefusal", data)
    this.setState({
      EditFormData: {
        ...this.state.EditFormData, AddVisaRefusal: data
      }
    })
  }

  AddTravelHistory = (values: Array<{ country: string; date: string; }>, SetFieldValue: (field: string, value: Array<{ country: string, date: string }>, shouldValidate?: boolean) => void) => {
    let data = [...values]
    let newdata = data.concat({
      country: '',
      date: ''
    })
    SetFieldValue("TravelHistory", newdata)
  }

  DeleteTravelHistory = (values: Array<{ country: string; date: string; }>, SetFieldValue: (field: string, value: Array<{ country: string, date: string }>, shouldValidate?: boolean) => void, index: number) => {
    let data = [...values];
    data.splice(index, 1);
    SetFieldValue("TravelHistory", data)
    this.setState({
      EditFormData: {
        ...this.state.EditFormData, TravelHistory: data
      }
    })
  }

  validatePassport = async (passportNo: string) => {
    this.validatePassportAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.validatePassport}?data[attributes][passport_number]=${passportNo}`
    });
  }

  setPassportValidation = (responseJson: { errors?: { message: string }, message: string }) => {
    if (responseJson?.errors?.message) {
      this.setState({ passportErrorMessage: responseJson.errors.message, isPassportErrorValid: true })
    }
    if (responseJson.message === "Passport Number does not exists") {
      this.setState({ passportErrorMessage: "", isPassportErrorValid: false })
    }
  };

  handleCountryCodeChange = (event: SelectEventType, name: string) => {
    if (name === "emercontryCodeValue") {
      this.setState({
        emercontryCodeValue: event.target.value as string, FormikFormReinitialize: true, EditFormData: {
          ...this.state.EditFormData, EmergencyMobile: ""
        }
      }, () => {
        this.setState({
          FormikFormReinitialize: false
        })
      });
    }
    if (name === "contryCodeValue") {
      this.setState({
        contryCodeValue: event.target.value as string, FormikFormReinitialize: true, EditFormData: {
          ...this.state.EditFormData, Mobile: ""
        }
      }, () => {
        this.setState({
          FormikFormReinitialize: false
        })
      });
    }
  };

  OnSubmitFuncation = async (values: {
    FirstName: string;
    LastName: string;
    Gender: string;
    MaritalStatus: string;
    Countryofnationality: string;
    Countryofresidence: string;
    State: string;
    City: string;
    PassportNumber: string;
    Mobile: string;
    Email: string;
    AssociatedBranch: string,
    EmergencyContactPerson: string,
    EmergencyMobile: string,
    EmergencyEmail: string,
    AddVisaRefusal: {
      country: string;
      date: string;
    }[],
    TravelHistory: {
      country: string;
      date: string;
    }[]
  }) => {
    const formData = new FormData();
    if (this.state.IsProfilePictureUpdate) {
      formData.append("[student][image]", this.state.ProfilePictureFile[0])
    }
    formData.append("[student][first_name]", values.FirstName)
    formData.append("[student][last_name]", values.LastName)
    formData.append("[student][gender]", values.Gender)
    formData.append("[student][passport_number]", values.PassportNumber)
    formData.append("[student][associate_branch_name]", values.AssociatedBranch)
    formData.append("[student][marital_status]", values.MaritalStatus)
    formData.append("[student][country_of_nationality]", values.Countryofnationality)
    formData.append("[student][country_of_residence]", values.Countryofresidence)
    formData.append("[student][state]", values.State)
    formData.append("[student][city]", values.City)
    formData.append("[student][mobile]", values.Mobile)
    formData.append("[student][email]", values.Email)
    formData.append("[student][emergency_contact_person]", values.EmergencyContactPerson)
    formData.append("[student][emergency_contact_mobile]", values.EmergencyMobile)
    formData.append("[student][emergency_contact_email]", values.EmergencyEmail)
    formData.append("[student][country_code]", this.state.contryCodeValue);
    formData.append("[student][emergency_country_code]", this.state.emercontryCodeValue);
    values.AddVisaRefusal.filter((fitems) => fitems.country !== "" && fitems.date !== "").forEach((item, index) => {
      formData.append(`[visa_refusals][${index}][country]`, item.country)
      formData.append(`[visa_refusals][${index}][date]`, moment(item.date).format("DD/MM/YYYY"))
    })
    values.TravelHistory.filter((fitems) => fitems.country !== "" && fitems.date !== "").forEach((item, index) => {
      formData.append(`[travel_histories][${index}][country]`, item.country)
      formData.append(`[travel_histories][${index}][date]`, moment(item.date).format("DD/MM/YYYY"))
    })
    const id = await getStorageData("StudentId")
    this.StudentUpDateAPICall = await this.ViewProfileApiCall({
      method: "PUT",
      endPoint: `/bx_block_dashboard/students/${id}`,
      type: "formData",
      body: formData
    });
  }

  DeleteDoument = async (DoumentDeleteId: string) => {
    const id = await getStorageData("StudentId")
    this.setState({
      loading: true
    })
    this.DeleteDoumentApiCallId = await this.ViewProfileApiCall({
      method: "DELETE",
      endPoint: `/bx_block_dashboard/students/delete_document?student_id=${id}&id=${DoumentDeleteId}`,
    });
  }

  HandelTabChange = (_e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      ActiveTabIndex: newValue
    })
  }

  FilterDocumentData = (type: string) => {
    const { StundetData } = this.state;
    return StundetData.documents.data.filter((filter) => filter.DocumentCategory === type)
  }

  OtherDocumentsData = () => {
    const { StundetData } = this.state;
    const DocumentCategory = ["English Test Score", "Education Documents", "Work Experience", "Passport Travel"]
    return StundetData.documents.data.filter((filter) => {
      if (DocumentCategory.includes(filter.DocumentCategory) === false) {
        return filter
      }
    })
  }



  OpenDocument = (Path: string) => {
    window.open(Path, "_blank")
  }

  UploadDoumentModelOpen = (UploadDocKey: string, UploadTitle: string, SubTitlt: string) => {
    this.setState({
      UploadDocumentAPIData: {
        APIKey: UploadDocKey,
        Title: UploadTitle,
        SubTitlt: SubTitlt
      },
      IsUploadDoumentModal: true
    })
  }

  isAcceptedFormat = (fileExtension?: string): boolean => {
    const acceptedFormats = ['png', 'jpg', 'pdf', 'doc', 'docx'];
    return fileExtension ? acceptedFormats.includes(fileExtension) : false;
  };

  getFileExtension = (file: File): string | undefined => {
    return file.name.split('.').pop()?.toLowerCase();
  };

  UploadFileMultipleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxFileSize: number = 10 * 1024 * 1024; // 10 MB in bytes
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const validFiles: File[] = [...this.state.UploadFIle];

      Array.from(selectedFiles).forEach((file) => {
        let Error = []
        if (file.size > maxFileSize) {
          Error.push(`${file.name} is too large. Maximum size is 10 MB.`)
          this.setState({
            UploadErrorText: Error
          })
        } else if (!this.isAcceptedFormat(file.name.split('.').pop()?.toLowerCase())) {
          Error.push(`${file.name} is not an accepted file type.`)
          this.setState({
            UploadErrorText: Error
          })
        } else {
          validFiles.push(file);
          const fileSizeInBytes = file.size;
          const fileSizeInKB = fileSizeInBytes / 1024;
          const fileSizeInMB = fileSizeInKB / 1024;

          let FilesSize = []
          if (fileSizeInMB > 1) {
            FilesSize.push(fileSizeInMB.toFixed(2) + "" + "Mb")
            this.setState({
              FileSize: FilesSize
            })
          } else {
            FilesSize.push(fileSizeInKB.toFixed(2) + "" + "KB")
            this.setState({
              FileSize: FilesSize
            })
          }
          this.setState({
            UploadFIle: validFiles,
            UploadErrorText: []
          })
        }
      });
    }

  }

  OnDeleteUpload = (index: number) => {
    this.setState({
      UploadErrorText: this.state.UploadErrorText.filter((_file, i) => i !== index)
    })
    this.setState({
      UploadFIle: this.state.UploadFIle.filter((_file, i) => i !== index)
    })
  }

  OnSaveUploadDocument = async () => {
    const { UploadFIle, StundetData, UploadDocumentAPIData } = this.state
    const formData = new FormData()
    UploadFIle.forEach((item, index) => {
      let newIndex = index + 1
      let ObjectIndex = Number(StundetData.documents.data.length) + 1 + Number(newIndex)
      formData.append(`[documents][${ObjectIndex}][doc]`, item)
      formData.append(`[documents][${ObjectIndex}][document_category]`, UploadDocumentAPIData.APIKey)
      formData.append(`[documents][${ObjectIndex}][name]`, item?.name)
    })
    if (UploadDocumentAPIData.APIKey === "Other Documents" && this.state.RemarkText !== "") {
      formData.append(`remarks`, this.state.RemarkText)
    }
    this.setState({
      loading: true
    })
    this.UploadDocumentForStudentAPICallId = await this.ViewProfileApiCall({
      method: "POST",
      endPoint: `/bx_block_dashboard/students/upload_doc?[student][email]=${StundetData.email}`,
      body: formData,
      type: "formData"
    });
  }

  CloseUploadModel = () => {
    this.setState({
      IsUploadDoumentModal: false,
      UploadFIle: [],
      UploadDocumentAPIData: {
        APIKey: "",
        Title: "",
        SubTitlt: ""
      },
      UploadErrorText: [],
      RemarkText: ""
    })
  }

  RemarkOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      RemarkText: e.target.value
    })
  }

  DownloadAllFiles = async () => {
    const urls = this.state.StundetData.documents.data.map((item) => item.FileUrl)
    urls.forEach((url) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = url.split('/').pop() as string;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Error downloading file:', error));
    });
  };

  GetSortListApiData = async () => {
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    this.GetShortListAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_catalogue/catalogues/show_course_shortlist?student_id=${StundetData.student_id}&id=${id}`
    });
  }

  ApplyNowButtonOfShortlistTab = async (itemsId: string) => {
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    const formData = new FormData();
    formData.append("generated_student_id", StundetData.student_id)
    formData.append("student_id", id)
    formData.append("course_id", itemsId)
    this.ApplyNowShortlistButtonApiCallId = await this.ViewProfileApiCall({
      method: "POST",
      endPoint: `/bx_block_leadmanagement/forms/apply_now`,
      type: "formData",
      body: formData
    });
  }

  NaviGatToCorseFidner = async () => {
    const { StundetData } = this.state
    const id = await getStorageData("StudentId")
    setStorageData("CourseFinderData", JSON.stringify({
      id: StundetData.student_id,
      firstName: StundetData.first_name,
      lastName: StundetData.last_name,
      image: StundetData.image ?? '',
      Studentid: id,
      passport_number: StundetData.passport_number ?? ''
    }))
    this.navigationToAnyPage("Catalogue")
  }

  HandelCountryOfresidence = (e: React.ChangeEvent<{
    name?: string; value: unknown;
  }>, SetFieldValue: (field: string,
    value: string, shouldValidate?:
      boolean) => void) => {
    SetFieldValue("Countryofresidence", e.target.value as string)
    const statedata = State.getAllStates()
    const filteredData = statedata.filter((data: { countryCode: string; }) => {
      return data.countryCode === e.target.value as string
    });
    this.setState({
      stateData: filteredData
    })
    SetFieldValue("State", "")
    SetFieldValue("City", "")
  }

  OpenVisaAndTravelModelOpen = (title: string, data: Array<{
    date: string;
    country: string;
  }>) => {
    this.setState({
      VisaAndTravelData: data,
      IsVisaAndTravelModelOpen: true,
      VisaAndTravelTitle: title
    })
  }

  OpenVisaAndTravelModelClose = () => {
    this.setState({
      VisaAndTravelData: [],
      IsVisaAndTravelModelOpen: false,
      VisaAndTravelTitle: ""
    })
  }

  HandelOpenAskForExpert = () => {
    this.setState({
      AskForExpertModel: !this.state.AskForExpertModel
    })
  }

  // Assigned Staff work Start

  getSalesManagerInfoApi = async () => {
    const { StundetData } = this.state
    this.GetAssignStaffCallId = await this.ViewProfileApiCall({
      method: configJSON.httpGetMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: `/bx_block_dashboard/students/assigned_staff?student[email]=${StundetData.email}`
    });
  };

  GetAssignedStaffAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffInfoResponse) => {
    if (apiRequestCallId === this.GetAssignStaffCallId) {
      const VissionStafResponse = responseJson.data.map((item) => {
        const SalesMangeData = item.attributes.sales_manager_info.attributes
        return {
          id: item.attributes.sales_manager_info.id,
          StaffType: item.attributes.sales_manager_info.type,
          FirstName: SalesMangeData.first_name,
          LastName: SalesMangeData.last_name,
          phone_number: SalesMangeData.phone_number,
          email: SalesMangeData.email,
          status: SalesMangeData.status,
          image: this.IsItValidValues(SalesMangeData.image),
          Role: `${SalesMangeData.role}`.replace(/_/g, " ")
        }
      })
      const UniversityStaff = responseJson.data.flatMap((item) =>
        item.attributes.university_staff_info.map((data) => {
          const attributes = data.attributes;
          return {
            id: data.id,
            StaffType: data.type,
            email: attributes.email,
            FirstName: attributes.first_name,
            image: this.IsItValidValues(attributes.image),
            LastName: attributes.last_name,
            PhoneNumber: attributes.phone_number,
            role: `${attributes.role}`.replace(/_/g, " "),
            status: attributes.status,
            UniversityName: attributes.university_name
          };
        })
      );

      const AgentStaff = responseJson.data.flatMap((item) =>
        item.attributes.agent_staff_info.map((data) => {
          const DataAttributes = data.attributes
          const ApplicationStageData = DataAttributes.application_stage
          return {
            FirstName: DataAttributes.agent.first_name,
            LastName: DataAttributes.agent.last_name,
            CountryCode: DataAttributes.agent.country_code,
            PhoneNumber: DataAttributes.agent.phone_number,
            Designation: DataAttributes.agent.designation,
            application_id: DataAttributes.application_id,
            student_id: DataAttributes.student_id,
            generated_student_id: DataAttributes.generated_student_id,
            agent_id: `${DataAttributes.agent_id}`,
            assignee_id: `${DataAttributes.assignee_id}`,
            application_status: DataAttributes.application_status,
            application_stage: {
              application_fees_paid: ApplicationStageData?.application_fees_paid,
              application_received: ApplicationStageData?.application_received,
              submitted_to_institution: ApplicationStageData?.submitted_to_institution,
              conditional_offer: ApplicationStageData?.conditional_offer,
              unconditional_offer: ApplicationStageData?.unconditional_offer,
              tuition_fees_paid: ApplicationStageData?.tuition_fees_paid,
              pre_cas_loa_stage: ApplicationStageData?.pre_cas_loa_stage,
              cas_loa_applied: ApplicationStageData?.cas_loa_applied,
              visa_applied: ApplicationStageData?.visa_applied,
              visa_received: ApplicationStageData?.visa_received,
            },
            course: DataAttributes.course,
            university: DataAttributes.university,
            agent_email: DataAttributes.agent_email,
            applied_by: DataAttributes.applied_by,
            start_date: DataAttributes.start_date,
          }
        })
      )
      this.setState({
        VisionStaffData: VissionStafResponse,
        UniversityStaff: UniversityStaff,
        AgentStaff: AgentStaff
      })
    }
  }

  // applicaton tab start

  StperData = () => {
    let data = ["Application Fees Paid", "Application", "Submitted to Institution", "Conditional Offer", "Unconditional Offer", "Tuition Fees Paid", "Pre-CAS/ LOA Stage", "CAS/LOA Applied", "CAS/LOA Received", "Visa Applied", "Visa Received"]
    return data
  }

  SecondStperData = () => {
    let data = [
      "Refund initiated",
      "Refund submitted to institution",
    ]
    return data
  }

  GetAplicationData = async () => {
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    this.setState({
      loading: true
    })
    this.getAplicationDataListApiCallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_leadmanagement/forms/applied_application?student_id=${id}&generated_student_id=${StundetData.student_id}`
    });
  }

  // Customizable Area End
}