// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Country, State } from "country-state-city";
import {
  CountryInterfaceData
} from "../../../../packages/blocks/contactus/src/ContactusController";
import * as Yup from "yup";
import { SelectEventType } from "./LeadManagementApplicationFormController.web";
import { toast } from "react-toastify";
import moment from "moment";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  token: null | string;
  toggleDrawer: boolean;
  userToken: string,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: { url: string },
    },
  },
  CardDetailsData: Array<{
    id: string | number, heading: string,
    points: string, imageUrl: string,
    PublishDate: string, AnnouncementType: string,
    ContryName: Array<string>
  }>,
  page: number,
  valueKey: string;
  updateModalOpen: boolean,
  updateValue: string,
  startDate: null | Date,
  endDate: null | Date,
  formattedStartDate: string,
  formattedEndDate: string,
  StundetData: {
    id: string,
    type: string;
    student_id: string;
    first_name: string;
    last_name: string;
    gender: string;
    country: string;
    country_code: string | null;
    marital_status: string;
    state: string;
    emergency_contact_email: string,
    city: string;
    mobile: string;
    email: string;
    emergency_contact_person: string;
    emergency_contact_mobile: string;
    current_status: string;
    passport_number: string;
    associate_branch_name: string;
    created_at: string;
    updated_at: string;
    agent_name: string;
    agent_id: string;
    documents: {
      data: Array<{
        Name: string, Id: string,
        FileType: string, DocumentCategory: string,
        AccountId: string, Comment: string,
        Status: string, FileUrl: string, FileSize: string
      }>
    };
    image: string | null;
    country_of_nationality: string | null,
    country_of_residence: string | null,
  },
  IsFormEdit: boolean,
  AssociateName: Array<{ id: number, name: string }>,
  EditFormData: {
    FirstName: string,
    LastName: string,
    Gender: string,
    MaritalStatus: string,
    Countryofnationality: string,
    Countryofresidence: string,
    State: string,
    City: string,
    PassportNumber: string,
    Mobile: string,
    Email: string,
    AssociatedBranch: string,
    EmergencyContactPerson: string,
    EmergencyMobile: string,
    EmergencyEmail: string,
    UserImage: string | null,
    AddVisaRefusal: Array<{ country: string, date: string }>,
    TravelHistory: Array<{ country: string, date: string }>,
  },
  FormikFormReinitialize: boolean,
  countryData: Array<CountryInterfaceData>;
  countryCodes: Array<{ alpha2: string; name: string; country_code: string; }>;
  contryCodeValue: string,
  emercontryCodeValue: string;
  stateData: Array<{ name: string }>;
  passportErrorMessage: string;
  isPassportErrorValid: boolean;
  IsStudentMobileValid: boolean;
  IsStudentMobileText: string;
  IsEmergencyMobileValid: boolean;
  IsEmergencyMobileText: string;
  ActiveTabIndex: number;
  ActiveTabIndex2: number;
  IsUploadDoumentModal: boolean;
  UploadDocumentAPIData: {
    Title: string,
    SubTitlt: string,
    APIKey: string,
  },
  UploadFIle: File[],
  FileSize: string[],
  UploadErrorText: string[],
  RemarkText: string,
  IsProfilePictureUpdate: boolean,
  ProfilePictureFile: File[],
  ShortListApiResponseData: Array<{
    Id: string,
    Type: string,
    ProgramName: string,
    ProgramLink: string,
    ProgramLevel: string,
    IsShortlisted: boolean,
    Discipline: string,
    FeesAfterScholarship: string,
    Fees: string,
    Duration: string
    CashDeposit: string
    UniversityId: string,
    UniversityName: string,
    UniversityLocation: string,
    UniversityProvince: string,
    UniversityYear: string,
    UniversityCountry: string,
    UniversityLogo: string,
    UniversityLogoFileName: string,
    UniversityOfferTat: string,
    Sessions: Array<{
      IntakeLabel: string,
      Availability: string,
      Month: Array<string>
    }>
  }>,
  VisaAndTravelData: Array<{
    date: string,
    country: string,
  }>,
  IsVisaAndTravelModelOpen: boolean,
  VisaAndTravelTitle: string,
  AskForExpertModel: boolean,
  VisionStaffData: any,
  studentServices: StudentServiceData;
  CourseInfo:any,
  otherApps:any,
  applicationReq:any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start
interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: { url: string };
  };
}

interface Requirement {
    id: string;
    status: string;
    task_name: string;
    task_description: string;
    sample_file_url?: string; // optional if it's not always present
  }

  const requirements: Requirement[] = [
  ];
interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export const configJSON = require("./config");

export interface StudentAPITypes {
  student: {
    data: {
      id: string;
      type: string;
      attributes: {
        student_id: string;
        first_name: string;
        last_name: string;
        gender: string;
        country: string;
        country_code: string | null;
        marital_status: string;
        country_of_nationality: string | null;
        country_of_residence: string | null;
        state: string;
        city: string;
        mobile: string;
        email: string;
        emergency_contact_person: string;
        emergency_contact_mobile: string;
        current_status: string;
        passport_number: string;
        associate_branch_name: string;
        created_at: string;
        updated_at: string;
        agent_name: string;
        emergency_contact_email: string,
        agent_id: string;
        emergency_country_code: string | null,
        travel_history: {
          data: Array<
            {
              id: string,
              type: string,
              attributes: {
                date: string,
                country: string
              }
            }
          >
        };
        visa_refusal: {
          data: Array<
            {
              id: string,
              type: string,
              attributes: {
                date: string,
                country: string
              }
            }
          >
        };
        documents: {
          data: Array<{
            id: string,
            type: string,
            attributes: {
              id: number,
              name: string,
              file_type: string,
              document_category: string,
              account_id: string,
              created_at: string,
              updated_at: string,
              comment: string,
              status: string,
              url: {
                url: string
              },
              file_size: string
            }
          }>
        };
        image: {
          filename: string,
          id: number,
          type: string,
          url: string
        } | null;
      }
    }
  }
}
interface UniversityLogo {
  id: number;
  filename: string;
  url: string;
  type: string;
}

interface University {
  id: number;
  university_name: string;
  location: string;
  province: string;
  country: string;
  associated_tags: string;
  offer_tat: string;
  year: number;
  logo: UniversityLogo;
}

interface Session {
  intake_label: string;
  months: string[];
  availability: string;
}

interface ProgramAttributes {
  program_name: string;
  program_level: string;
  duration: string;
  fees: string;
  fees_after_scholarship: string;
  cas_deposit: string;
  program_link: string;
  discipline: string;
  is_shortlisted: boolean;
  university: University;
  sessions: Session[];
}

interface ProgramData {
  id: string;
  type: string;
  attributes: ProgramAttributes;
}

interface ShortListApiResponse {
  data: ProgramData[];
}
interface SalesManagerInfo {
  data: {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      phone_number: string;
      email: string;
      status: string;
      image: string;
    };
  };
}
interface AssignedStaffInfo {
  id: string;
  type: string;
  attributes: {
    university_staff_info: object;
    sales_manager_info: SalesManagerInfo;
  };
}

// Define the structure for the overall response
interface AssignedStaffResponseData {
  data: AssignedStaffInfo[]; // Array of assigned staff info
  meta: {
    message: string; // e.g., "Assigned Staff information"
  };
}

interface StudentServiceData {
  data: StudentService[];
}

interface StudentService {
  id: string;
  type: string;
  attributes: StudentServiceAttributes;
}

interface StudentServiceAttributes {
  request_id: string;
  status: "PENDING" | "APPROVED" | "REJECTED";
  service: string;
  logo: string;
  student_name: string;
  date: string;
  time: string;
}

// Customizable Area End

export default class ViewApplicationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  GetStudentsAPICallId: string = "";
  getAssociateBranchNamesAPICallID: string = "";
  getCountryCodeAPICallID: string = "";
  validatePassportAPICallID: string = "";
  validateStudentMobileNoAPICallID: string = "";
  validateEmergencyMobileNoAPICallID: string = "";
  StudentUpDateAPICall: string = "";
  DeleteDoumentApiCallId: string = "";
  UploadDocumentForStudentAPICallId: string = "";
  GetAssignStaffCallId: string = "";
  GetCourseInfoCallId: string = "";
  GetOtherAppsInfoCallId: string="";
  GetApplicationResCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      loading: true,
      token: null,
      toggleDrawer: false,
      userToken: '',
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
      CardDetailsData: [],
      page: 1,
      valueKey: '',
      updateModalOpen: false,
      updateValue: '',
      startDate: null,
      endDate: null,
      formattedStartDate: "",
      formattedEndDate: "",
      StundetData: {
        id: '',
        type: '',
        student_id: "",
        first_name: "",
        last_name: "",
        gender: "",
        country: "",
        country_code: "",
        marital_status: "",
        state: "",
        city: "",
        mobile: "",
        email: "",
        emergency_contact_person: "",
        emergency_contact_mobile: "",
        current_status: "",
        passport_number: "",
        associate_branch_name: "",
        created_at: "",
        updated_at: "",
        agent_name: "",
        agent_id: "",
        documents: { data: [] },
        image: "",
        country_of_nationality: "",
        country_of_residence: "",
        emergency_contact_email: ""
      },
      IsFormEdit: false,
      AssociateName: [],
      countryCodes: [],
      contryCodeValue: "",
      emercontryCodeValue: "",
      EditFormData: {
        FirstName: "",
        LastName: "",
        Gender: "",
        MaritalStatus: "",
        Countryofnationality: "",
        Countryofresidence: '',
        State: "",
        City: "",
        PassportNumber: "",
        Mobile: "",
        Email: "",
        AssociatedBranch: "",
        EmergencyContactPerson: "",
        EmergencyMobile: "",
        EmergencyEmail: "",
        UserImage: "",
        AddVisaRefusal: [{
          country: "",
          date: ""
        }],
        TravelHistory: [{
          country: "",
          date: ""
        }]
      },
      FormikFormReinitialize: false,
      countryData: Country.getAllCountries() as never,
      stateData: [],
      passportErrorMessage: "",
      isPassportErrorValid: false,
      IsStudentMobileValid: false,
      IsStudentMobileText: "",
      IsEmergencyMobileValid: false,
      IsEmergencyMobileText: "",
      ActiveTabIndex: 0,
      ActiveTabIndex2: 0,
      IsUploadDoumentModal: false,
      UploadDocumentAPIData: {
        Title: "",
        SubTitlt: "",
        APIKey: "",
      },
      UploadFIle: [],
      FileSize: [],
      UploadErrorText: [],
      RemarkText: "",
      IsProfilePictureUpdate: false,
      ProfilePictureFile: [],
      ShortListApiResponseData: [],
      VisaAndTravelData: [],
      IsVisaAndTravelModelOpen: false,
      VisaAndTravelTitle: "",
      AskForExpertModel: false,
      VisionStaffData: [],
      studentServices: {
        data: []
      },
      CourseInfo:[],
      otherApps:[],
      applicationReq:[],
    };

    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.GetAPICallFuncation(apiRequestCallId, responseJson)
        this.getAssosiateAPICall(apiRequestCallId, responseJson)
        if (apiRequestCallId === this.getCountryCodeAPICallID) {
          this.setCountryCodes(responseJson)
        }
        this.StudenAPiCallRecied(apiRequestCallId, responseJson)
        this.DeleteAPICallFuncationResponseSet(apiRequestCallId, responseJson)
        this.UploadDocumentAPICallResponse(apiRequestCallId, responseJson)
        this.GetAssignedStaffAPICallResponse(apiRequestCallId, responseJson)
        this.GetCourseInfoAPICallResponse(apiRequestCallId,responseJson)
        this.GetOtherAppsAPICallResponse(apiRequestCallId,responseJson)
        this.GetapplicationReqCallResponse(apiRequestCallId,responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  UploadDocumentAPICallResponse = async (apiRequestCallId: string, responseJson: { success: string, error?: Array<string> }) => {
    if (apiRequestCallId === this.UploadDocumentForStudentAPICallId) {
      if (responseJson.success === "Documents uploaded successfully") {
        const id = await getStorageData("StudentId")
        this.GetStudentsData(id)
        this.CloseUploadModel()
        toast.success(responseJson.success)
      } else {
        if (responseJson.error) {
          this.CloseUploadModel()
          this.setState({
            loading: false
          })
          toast.error(responseJson.error[0])
          this.ErrorToastHander({ error: [{ message: responseJson.error[0] }] })
        }
      }
    }
  }

  DeleteAPICallFuncationResponseSet = async (apiRequestCallId: string, responseJson: { message: string }) => {
    if (apiRequestCallId === this.DeleteDoumentApiCallId) {
      if (responseJson.message === "Document deleted successfully") {
        toast.success(responseJson.message)
        const id = await getStorageData("StudentId")
        this.GetStudentsData(id)
      }
    }
  }

  GetAPICallFuncation = (apiRequestCallId: string, responseJson: UserDetailsResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.getProfileFormSuccessCallBack(responseJson)
    }
  }

  getAssosiateAPICall = (apiRequestCallId: string, responseJson: {
    data:
    Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string;
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  }) => {
    if (apiRequestCallId === this.getAssociateBranchNamesAPICallID) {
      this.setAssociateNameResponse(responseJson)
    }
  }
  ErrorToastHander = (responseJson: { [key: string]: Array<{ [key: string]: string }> }) => {
    if (Array.isArray(responseJson.error)) {
      toast.error(responseJson.error[0])
    } else {
      toast.error(Object.values(responseJson.error)[0] as string)
    }
  }

  StudenAPiCallRecied = (apiRequestCallId: string, responseJson: StudentAPITypes) => {
    if (apiRequestCallId === this.GetStudentsAPICallId) {
      this.SetStudentDataAPIcall(responseJson)
    }
  }

  IsItValidValues = (value: string | null | undefined) => {
    if (typeof value === "string") {
      return value
    } else {
      return ""
    }

  }

  SetStudentDataAPIcall = (responseJson: StudentAPITypes) => {
    const AttributesResponse = responseJson.student.data.attributes;

    if (AttributesResponse) {
      const DoumentData = AttributesResponse.documents.data?.map((item) => {
        return {
          Name: item.attributes.name,
          Id: `${item.attributes.id}`,
          FileType: item.attributes.file_type,
          DocumentCategory: item.attributes.document_category,
          AccountId: item.attributes.account_id ?? '',
          Comment: item.attributes.comment ?? '',
          Status: item.attributes.status,
          FileUrl: item.attributes?.url?.url,
          FileSize: item.attributes.file_size
        }
      })

      this.setState({
        StundetData: {
          id: responseJson.student.data.id,
          type: responseJson.student.data.type,
          student_id: AttributesResponse.student_id,
          first_name: AttributesResponse.first_name,
          last_name: AttributesResponse.last_name,
          gender: AttributesResponse.gender,
          country: AttributesResponse.country,
          country_code: AttributesResponse.country_code,
          marital_status: AttributesResponse.marital_status,
          state: AttributesResponse.state,
          city: AttributesResponse.city,
          mobile: AttributesResponse.mobile,
          email: AttributesResponse.email,
          emergency_contact_person: AttributesResponse.emergency_contact_person,
          emergency_contact_mobile: AttributesResponse.emergency_contact_mobile,
          emergency_contact_email: AttributesResponse.emergency_contact_email,
          current_status: AttributesResponse.current_status,
          passport_number: AttributesResponse.passport_number,
          associate_branch_name: AttributesResponse.associate_branch_name,
          created_at: AttributesResponse.created_at,
          updated_at: AttributesResponse.updated_at,
          agent_name: AttributesResponse.agent_name,
          agent_id: AttributesResponse.agent_id,
          documents: { data: DoumentData },
          image: this.IsItValidValues(AttributesResponse.image?.url),
          country_of_nationality: AttributesResponse.country_of_nationality,
          country_of_residence: AttributesResponse.country_of_residence,
        },
        contryCodeValue: this.IsItValidValues(AttributesResponse.country_code),
        emercontryCodeValue: this.IsItValidValues(AttributesResponse.emergency_country_code),
      }, () => {
        const { StundetData } = this.state
        this.LoderFalseTrue()
        this.getSalesManagerInfoApi();
        this.getCourseInfoApi();
        this.getOtherAppsInfoApi();
        const statedata = State.getAllStates()
        const filteredData = statedata.filter((data: { countryCode: string; }) => {
          return data.countryCode === StundetData.country_of_residence
        });
        this.setState({
          stateData: filteredData
        })
      });
    }

  }

  LoderFalseTrue = () => {
    this.setState({
      loading: !this.state.loading
    })
  }


  async componentDidMount() {
    const token = await getStorageData("token")
    const id = await getStorageData("StudentId")
    this.GetStudentsData(id)
    this.getSalesManagerInfoApi()
    this.getCourseInfoApi()
    this.getOtherAppsInfoApi();
    this.getApplicationResApi();
    this.setState({ userToken: token }, () => {
      this.getFormApprovalProfile()
      this.getAssociateBranchNames()
      this.getCountryCode()
    })
  }

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage",
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  ViewProfileApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };


  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }

  GetStudentsData = async (id: string) => {
    this.setState({
      loading: true
    })
    this.GetStudentsAPICallId = await this.ViewProfileApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_dashboard/students/${id}`
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleOpenClose = (value?: string) => {
    this.setState({
      updateModalOpen: !this.state.updateModalOpen,
      updateValue: `${value}`,
    });
  };



  getAssociateBranchNames = async () => {
    this.getAssociateBranchNamesAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getAssociateBranchNames
    });
  }
  setAssociateNameResponse = (response: {
    data:
    Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string;
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  }) => {
    const data = response?.data.map((item) => {
      return {
        id: item.attributes.id,
        name: item.attributes.branch_name
      }
    })
    this.setState({
      AssociateName: data
    })
  }

  getCountryCode = async () => {
    this.getCountryCodeAPICallID = await this.ViewProfileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getCountryCode
    });
  }

  setCountryCodes = (responseJson: Array<{
    alpha2: string,
    name: string,
    country_code: string
  }>) => {
    this.setState({ countryCodes: responseJson })
    const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
    this.setState({ contryCodeValue: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}`, emercontryCodeValue: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}` })
  };

  DeleteDoument = async (DoumentDeleteId: string) => {
    const id = await getStorageData("StudentId")
    this.setState({
      loading: true
    })
    this.DeleteDoumentApiCallId = await this.ViewProfileApiCall({
      method: "DELETE",
      endPoint: `/bx_block_dashboard/students/delete_document?student_id=${id}&id=${DoumentDeleteId}`,
    });
  }

  HandelTabChange = (_e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      ActiveTabIndex: newValue
    })
  }

  HandelTabChange2 = (_e: React.ChangeEvent<{}>, newValue2: number) => {
    this.setState({
      ActiveTabIndex2: newValue2
    })
  }

  FilterDocumentData = (type: string) => {
    const { StundetData } = this.state;
    return StundetData.documents.data.filter((filter) => filter.DocumentCategory === type)
  }



  OpenDocument = (Path: string) => {
    window.open(Path, "_blank")
  }

  UploadDoumentModelOpen = (UploadDocKey: string, UploadTitle: string, SubTitlt: string) => {
    this.setState({
      UploadDocumentAPIData: {
        APIKey: UploadDocKey,
        Title: UploadTitle,
        SubTitlt: SubTitlt
      },
      IsUploadDoumentModal: true
    })
  }

  isAcceptedFormat = (fileExtension?: string): boolean => {
    const acceptedFormats = ['png', 'jpg', 'pdf', 'doc', 'docx'];
    return fileExtension ? acceptedFormats.includes(fileExtension) : false;
  };


  OnDeleteUpload = (index: number) => {
    this.setState({
      UploadErrorText: this.state.UploadErrorText.filter((_file, i) => i !== index)
    })
    this.setState({
      UploadFIle: this.state.UploadFIle.filter((_file, i) => i !== index)
    })
  }

  OnSaveUploadDocument = async () => {
    const { UploadFIle, StundetData, UploadDocumentAPIData } = this.state
    const formData = new FormData()
    UploadFIle.forEach((item, index) => {
      let newIndex = index + 1
      let ObjectIndex = Number(StundetData.documents.data.length) + 1 + Number(newIndex)
      formData.append(`[documents][${ObjectIndex}][doc]`, item)
      formData.append(`[documents][${ObjectIndex}][document_category]`, UploadDocumentAPIData.APIKey)
      formData.append(`[documents][${ObjectIndex}][name]`, item?.name)
    })
    if (UploadDocumentAPIData.APIKey === "Other Documents" && this.state.RemarkText !== "") {
      formData.append(`remarks`, this.state.RemarkText)
    }
    this.setState({
      loading: true
    })
    this.UploadDocumentForStudentAPICallId = await this.ViewProfileApiCall({
      method: "POST",
      endPoint: `/bx_block_dashboard/students/upload_doc?[student][email]=${StundetData.email}`,
      body: formData,
      type: "formData"
    });
  }

  CloseUploadModel = () => {
    this.setState({
      IsUploadDoumentModal: false,
      UploadFIle: [],
      UploadDocumentAPIData: {
        APIKey: "",
        Title: "",
        SubTitlt: ""
      },
      UploadErrorText: [],
      RemarkText: ""
    })
  }

  RemarkOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      RemarkText: e.target.value
    })
  }

  // Assigned Staff work Start

  getSalesManagerInfoApi = async () => {
    const { StundetData } = this.state
    this.GetAssignStaffCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `/bx_block_dashboard/students/assigned_staff?student[email]=${StundetData?.email}`
    });
  };

  getCourseInfoApi = async () => {
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    this.GetCourseInfoCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.courseInfoEndPoint}?student_id=${StundetData.student_id}&id=${id}`
    });
  };

  getOtherAppsInfoApi = async () => {
    const id = await getStorageData("StudentId")
    const { StundetData } = this.state
    this.GetOtherAppsInfoCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.otherAppsEndPoint}?student_id=${StundetData.student_id}&id=${id}`
    });
  };
  getApplicationResApi = async () => {
    this.GetApplicationResCallId = await this.ViewProfileApiCall({
        method: configJSON.httpGetMethod,
        contentType: configJSON.validationApiContentType,
        endPoint: `${configJSON.AppResEndPoint}?application_id=104`
    });
  };

  GetAssignedStaffAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetAssignStaffCallId) {
    this.setState({VisionStaffData:responseJson.data})
    }
  }

  GetCourseInfoAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetCourseInfoCallId) {
    this.setState({CourseInfo:responseJson})
    }
  }

  GetOtherAppsAPICallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetOtherAppsInfoCallId) {
    this.setState({otherApps:responseJson})
    }
  }

  GetapplicationReqCallResponse = (apiRequestCallId: string, responseJson: AssignedStaffResponseData) => {
    if (apiRequestCallId === this.GetApplicationResCallId) {
    this.setState({applicationReq:responseJson})
    }
  }

  // applicaton tab start

  StperData = () => {
    let data = ["Application Fees Paid", "Application", "Submitted to Institution", "Conditional Offer", "Unconditional Offer", "Tuition Fees Paid", "Pre-CAS/ LOA Stage", "CAS/LOA Applied", "CAS/LOA Received", "Visa Applied", "Visa Received"]
    return data
  }

  SecondStperData = () => {
    let data = [
      "Refund initiated",
      "Refund submitted to institution",
      "Refund Received",
      "Refund Rejected"
    ]
    return data
  }

  // Customizable Area End
}