import React from "react";
// Customizable Area Start
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import InstitutionController, { Props } from "./InstitutionController.web";
import { AppBar, Box, Button, Grid, Tab, Tabs, Typography, SvgIcon, TextField, Checkbox, ListItemText, InputAdornment, Chip, Paper, Modal, Select, MenuItem } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { universityLocation, locationIcon, fileicon, cakeIcon, addIconWhite } from './assets';
import {
    FileDownload as FileDownloadIcon,
  } from '@mui/icons-material';
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@mui/material/Autocomplete";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Bounce, ToastContainer } from "react-toastify";
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class Institution extends InstitutionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderUniversityDetails = () => {
        const {
            university_name,
            logo,
            uni_location,
            uni_country,
            ranking,
            institution_type,
            sponsor_license_number,
            offer_tat,
            application_fees,
            post_study_work_permit,
            conditional_offer_letter,
            additional_services,
        } = this.state;
    
        return (
            <Box className="universityDetailsWrapper">
                <Box className="nameAndLogoWrapper">
                    <Box className="logoUniverstity">
                        <img src={logo.url} className="universityLogo" alt={university_name} />
                    </Box>
                    <Box className="nameLocationUniverstity">
                        <Typography className="nameUniversity">{university_name}</Typography>
                        <Box className="unversityLocation">
                            <img src={universityLocation} alt="Location Icon" />
                            <Typography className="locationUniversity">{`${uni_location}, ${uni_country}`}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className="PersonalInfoContainer">
                    <Grid container className="PersonalInfoGridContainer">
                        <Grid item md={6} xs={12} className="Section">
                            <Grid container className="DetailsContainer">
                                {[
                                    { label: "Ranking", value: ranking },
                                    { label: "Institution type", value: institution_type },
                                    { label: "Sponsor license / DLI number", value: sponsor_license_number },
                                    { label: "Offer TAT", value: offer_tat ? "Yes" : "No" },
                                ].map((item, index) => (
                                    <Grid container key={index} className="gridSection">
                                        <Grid item xs={6} className="universityBasics" style={{ border: index === 3 ? "none" : "" }}>
                                            <Typography variant="body1" className="Title LightcolorText">
                                                {item.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} className="universityBasics" style={{ border: index === 3 ? "none" : "" }}>
                                            <Typography variant="body1" className="Title BoldTitle">
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="Section">
                            <Grid container className="DetailsContainer">
                                {[
                                    { label: "Application fees", value: `$${application_fees}` },
                                    { label: "Post Study Work Permit", value: post_study_work_permit ? "Yes" : "No" },
                                    { label: "Conditional Offer Letter", value: conditional_offer_letter ? "Yes" : "No" },
                                    { label: "Additional services", value: additional_services, isHTML: true },
                                ].map((item, index) => (
                                    <Grid container key={index} className="gridSection">
                                        <Grid item xs={6} className="universityBasics" style={{ border: index === 3 ? "none" : "" }}>
                                            <Typography variant="body1" className="Title LightcolorText">
                                                {item.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} className="universityBasics" style={{ border: index === 3 ? "none" : "" }}>
                                            {item.isHTML ? <Typography
                                                variant="body1"
                                                className="Title BoldTitle"
                                                component="div"
                                                dangerouslySetInnerHTML={{ __html: item.value }}
                                            /> :
                                                <Typography variant="body1" className="Title BoldTitle">
                                                    {item.value}
                                                </Typography>}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }

    renderTab = () => {
        return (
            <MainTabBox>
                <div >
                    <AppBar position="static">
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            data-test-id="tabChangeTestId"
                            className="tabBarUniversity"
                        >
                            <Tab className="tabLabel" label="Overview" value={0} data-test-id="bankInfoTestId" />
                            <Tab className="tabLabel" label="Workflow" value={1} />
                            <Tab className="tabLabel" label="Eligibilty" value={2} />
                            <Tab className="tabLabel" label="Programs" value={3} data-test-id="manageStaffTab" />
                            <Tab className="tabLabel" label="Documents & Templates" value={4} />
                            <Tab className="tabLabel" label="Interview Prep Material" value={5} />
                        </Tabs >
                    </AppBar >
                    {this.tabPanel(this.renderOverview(), this.state.tabValue, 0)}
                    {this.tabPanel(this.renderWorkflow(), this.state.tabValue, 1)}
                    {this.tabPanel(this.renderEligibilty(), this.state.tabValue, 2)}
                    {this.tabPanel(this.renderPrograms(), this.state.tabValue, 3)}
                    {this.tabPanel(this.renderDocumentsTemplates(), this.state.tabValue, 4)}
                    {this.tabPanel(this.renderInterview(), this.state.tabValue, 5)}
                </div >
            </MainTabBox >
        )
    }

    renderSlideShow = () => {
        const {images, slideIndex} = this.state
        return (
            <SlideShowWrapper>
            {images.map((image, index) => (
                <Box
                    className="slides"
                    style={{ display: index === slideIndex ? 'block' : 'none' }}
                    key={index}
                >
                    <img 
                        src={image.url}
                        className="imageSlide" 
                        alt={`Slide ${index + 1}`} 
                        style={{ width: '100%' }} 
                    />
                </Box>
            ))}
        
            <a className="prev" onClick={() => this.changeSlide(-1)}>&#10094;</a>
            <a className="next" onClick={() => this.changeSlide(1)}>&#10095;</a>
        
            <Box className="thumbnail-container">
                <Box className="thumbnails">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image.url}
                            alt={`Thumbnail ${index + 1}`}
                            className={`thumbnailImage ${slideIndex === index ? 'active' : ''}`}
                            onClick={() => this.showSlide(index)}
                            style={{ cursor: 'pointer', width: '100px', opacity: slideIndex === index ? 1 : 0.6 }}
                        />
                    ))}
                </Box>
            </Box>
        </SlideShowWrapper>
        )
    }

    renderCampusLocation = () => {
        const {locations} = this.state
        return (
            <Box className="campusLocationBox">
                <Typography className="aboutUsTitle">Campus locations</Typography>
                <Grid container spacing={4} className="locationWrapper">
                    {locations && locations.map((address, index) => {
                        return (
                            <Grid className="locationBox" item xs={12} sm={12} md={3} key={index}>
                                <Box className="locationIcon">
                                    <img src={locationIcon} />
                                </Box>
                                <Box className="locationDetails">
                                    <Typography component="div" dangerouslySetInnerHTML={{ __html: address.location }} />
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        )
    }

    renderOverview = () => {
        const {images, locations, overview} = this.state
        return (
            <OverViewWrapper>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className="aboutUsTitle">About</Typography>
                        <Typography component="div" dangerouslySetInnerHTML={{ __html: overview }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        {images && this.renderSlideShow()}
                    </Grid>
                </Grid>
                {locations && this.renderCampusLocation()}
            </OverViewWrapper>
        )
    }

    renderWorkflow = () => {
        return <Typography component="div" dangerouslySetInnerHTML={{ __html: this.state.workflow }} />
    }

    renderEligibilty = () => {
        return <Typography component="div" dangerouslySetInnerHTML={{ __html: this.state.eligibility }} />
    }

    renderFilters = () => {
        return (
            <FilterGrid container style={{ gap: "10px" }}>
                <Grid item xs={12} sm={12} md={3} className="filterGridsSearch">
                    <Box
                        className="input-box-wrap">
                        <InputField
                            name="passport"
                            className="search-bar"
                            data-test-id="passportId"
                            placeholder="Search for programs,institution or other keywords"
                            value={this.state.searchTerm}
                            onChange={this.handleSearchTermChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2} className="filterGrids">
                    <Box className="input-box-wrap">
                        <Autocomplete
                            multiple
                            value={this.state.selectedPrograms}
                            getOptionLabel={(country: string) => country}
                            disableCloseOnSelect
                            data-test-id="selectedPrograms"
                            className="selectInput autocompleteField"
                            renderOption={(props, country: string, { selected }) => {
                                const { key, ...otherProps } = props;
                                return (
                                    <Box component="li" key={country} {...otherProps}>
                                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                                        <ListItemText primary={country} />
                                    </Box>
                                );
                            }}
                            options={this.state.universityPrograms}
                            onChange={this.handleProgramChange}
                            renderInput={(params) => (<TextField
                                {...params}
                                className="intakePlaceholder"
                                variant="standard"
                                placeholder={"Program Level"}
                                InputLabelProps={{ shrink: true, ...params?.InputProps }}
                            />
                            )}
                            renderTags={() => null}
                        />
                        <Box mt={2}>
                            {this.state.selectedPrograms.map((option) => (
                                <StyledChip
                                    key={option}
                                    label={option}
                                    data-test-id="removedSelectedCountries"
                                    onDelete={() => this.handleRemovePrograms(option)}
                                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}

                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2} className="filterGrids">
                    <Box className="input-box-wrap">
                        <Autocomplete
                            multiple
                            value={this.state.selectedDiscipline}
                            disableCloseOnSelect
                            data-test-id="selectedDiscipline"
                            className="selectInput autocompleteField"
                            options={this.state.universityDiscipline}
                            getOptionLabel={(country: string) => country}
                            onChange={this.handleDisciplineChange}
                            renderOption={(props, country: string, { selected }) => {
                                const { key, ...otherProps } = props;
                                return (
                                    <Box component="li" key={country} {...otherProps}>
                                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                                        <ListItemText primary={country} />
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (<TextField
                                {...params}
                                className="intakePlaceholder"
                                variant="standard"
                                placeholder={"Discipline"}
                                InputLabelProps={{ shrink: true, ...params?.InputProps }}

                            />
                            )}
                            renderTags={() => null}
                        />
                        <Box mt={2}>
                            {this.state.selectedDiscipline.map((option) => (
                                <StyledChip
                                    data-test-id="removedSelectedDiscipline"
                                    key={option}
                                    onDelete={() => this.handleRemoveDiscipline(option)}
                                    label={option}
                                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}

                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={2} className="filterGrids">
                    <Box className="input-box-wrap">
                        <Autocomplete
                            multiple
                            options={this.state.universityIntakes}
                            getOptionLabel={(country: string) => country}
                            disableCloseOnSelect
                            onChange={this.handleIntakeChange}
                            value={this.state.selectedIntakes}
                            data-test-id="selectedIntakes"
                            className="selectInput autocompleteField"
                            renderOption={(props, country: string, { selected }) => {
                                const { key, ...otherProps } = props;
                                return (
                                    <Box component="li" key={country} {...otherProps}>
                                        <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                                        <ListItemText primary={country} />
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (<TextField
                                {...params}
                                InputLabelProps={{ shrink: true, ...params?.InputProps }}
                                className="intakePlaceholder"
                                variant="standard"
                                placeholder={"Intakes"}
                            />
                            )}
                            renderTags={() => null}
                        />
                        <Box mt={2}>
                            {this.state.selectedIntakes.map((option) => (
                                <StyledChip
                                    label={option}
                                    data-test-id="removedSelectedIntakes"
                                    key={option}
                                    onDelete={() => this.handleRemoveIntakes(option)}
                                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}

                                />
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </FilterGrid>
        )
    }

    renderMangeShortlist = () => {
        return (
            <MainModal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={this.state.MangeShortListModel}
                className="modal"
                data-test-id="MangeStaffModel"
                onClose={() => this.OpenCloseMangeStaff()}
            >
                <UpdatedMainModelStyle style={{ flexDirection: 'column' }} className="MangeStaff-paper" data-test-id="ManeStaffpaperTestID">
                    <BoxPopupClose data-test-id="MangeStaffPopupCloseTestID">
                        <CloseIcon
                            data-test-id="MangeStaffcloseIconTestID"
                            onClick={() => this.OpenCloseMangeStaff()}
                        />
                    </BoxPopupClose>
                    <h4 style={{ margin: 0, fontSize: '20px' }}>Add to shortlist</h4>
                    <p style={{ margin: 0 }}>Select the student for which this program should be shortlisted. Once shortlisted selected program list will also be displayed in Student profile.</p>
                    <Box style={{ width: "100%" }}>
                        <Box className="HeadingContainer">
                            <Box style={{ width: '100%' }} >
                                <Typography className="NewHeading" data-test-id="MangeStaffheadingTestID">
                                    <div className="manageShortlist-list">
                                        <strong>{`${this.state.shortListData?.attributes?.university?.university_name} , ${this.state.shortListData?.attributes?.university?.location}`}</strong>
                                        <p>{this.state.shortListData?.attributes?.program_name}</p>
                                        <span>
                                            {`${this.state.shortListData?.attributes?.sessions
                                                ?.filter((session: any) => session.availability === "open")
                                                ?.map((session: any) => session.intake_label)
                                                .join(', ')}`}
                                        </span>
                                    </div>
                                </Typography>
                                <p style={{ fontSize: '16px', fontWeight: '600' }}>Student</p>
                                <Select
                                    value={this.state.selectedStudent?.id || ""}
                                    onChange={this.handleStudentChange}
                                    displayEmpty
                                    fullWidth
                                    className="student-select"
                                    data-test-id="selectStudentDropdown"
                                >
                                    <MenuItem value="" disabled>
                                        Select a student
                                    </MenuItem>
                                    {this.state.studentList?.map((student: any) => (
                                        <MenuItem key={student.id} value={student.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <p style={{ margin: 0 }}>{student.first_name} {student.last_name}</p><div>
                                                <strong>Student ID:</strong> {student.student_id}, <strong>Passport No:</strong> {student.passport_number}</div>
                                        </MenuItem>
                                    ))}
                                </Select>
                                <div style={{ textAlign: 'center' }}>
                                    <Button style={{
                                        backgroundColor: "#00D794",
                                        color: "white",
                                        height: "44px",
                                        padding: "0px 16px",
                                        borderRadius: "8px",
                                        textTransform: "none",
                                        whiteSpace: 'nowrap',
                                        marginTop: '10%'
                                    }}
                                    data-test-id="shorlistStudent"
                                        onClick={this.uploadShortlist}
                                    >
                                        <Typography style={{ textTransform: "none", fontWeight: 600, fontSize: "16px" }}>Add to the chosen student shortlist</Typography>
                                    </Button>
                                </div>

                            </Box>
                        </Box>
                    </Box>
                </UpdatedMainModelStyle>
            </MainModal>
        );
    };
    
    showErrorToast = () => {
        return (
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            theme="colored"
            transition={Bounce}
          />
        )
      }
      
    renderPrograms = () => {
        const {universityCourses, searchResults} = this.state

        const filteredCourses = searchResults.length > 0 ? searchResults : universityCourses
        
        if (filteredCourses.length === 0) {
            return (
                <>
                {this.renderFilters()}
                <DashboardBox>
                    <p>No programs available</p>
                </DashboardBox>
                </>
            )
        }

        return (
            <>
                {this.renderFilters()}
                {filteredCourses && filteredCourses.map((program, index) => (
                    <DashboardBox key={program.id}>
                        <Box
                            style={{ ...webStyles.backgroundColor }}
                            className="contentBox"
                        >
                            <Box className="content-finder">
                                <Box
                                    className="university-boxes"
                                >
                                    <Box display="flex" alignItems="center">
                                        <img
                                            src={
                                                program?.attributes?.university?.logo?.url ||
                                                "https://www.w3schools.com/html/pic_trulli.jpg"
                                            }
                                            alt="University logo"
                                            className="university-logo"
                                        />
                                        <Box>
                                            <Typography className="course-heading">
                                                {program.attributes.program_name}
                                            </Typography>
                                            <div
                                                className="course-heading-wrap"
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginRight: "20%",
                                                    }}
                                                >
                                                    <img
                                                        src={cakeIcon}
                                                        alt="WhatsApp Icon"
                                                    />
                                                    <Typography
                                                        className="university"
                                                        color="textSecondary"
                                                    >
                                                        {
                                                            program.attributes.university
                                                                .university_name
                                                        }
                                                    </Typography>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        src={locationIcon}
                                                        alt="WhatsApp Icon"
                                                    />
                                                    <Typography
                                                        className="university"
                                                        color="textSecondary"
                                                        style={{ marginLeft: "5%" }}
                                                    >
                                                        {program.attributes.university.location},
                                                        {program.attributes.university.country}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Box>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="shortListbtn"
                                        onClick={() => this.shortListModal(program)}
                                        data-test-id={`shortlist-btn-`+index}
                                    >
                                        <img src={addIconWhite} alt="Add Icon" />
                                        Add to shortlist
                                    </Button>
                                </Box>
                               
                                <Box
                                    sx={{
                                        borderTop: "2px solid #B6373C",
                                        marginTop: "26px",
                                        paddingTop: "26px",
                                    }}
                                >
                                    {/* Intake Information */}
                                    <Box
                                        className="intake-box"
                                        display="flex"
                                        justifyContent="space-around"
                                    >
                                        {program?.attributes?.sessions?.map(
                                            (session: any, index: number) => (
                                                <Box
                                                    key={index}
                                                    textAlign="center"
                                                    className="intake-box-detail"
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        style={{ fontWeight: '700', fontFamily: 'Plus Jakarta Sans' }}
                                                    >
                                                        {session.intake_label}
                                                    </Typography>

                                                    {/* Display the availability as status */}
                                                    <span
                                                        className={
                                                            session.availability === "open"
                                                                ? "statusActive"
                                                                : "statusInactive"
                                                        }
                                                    >
                                                        {session.availability}
                                                    </span>
                                                </Box>
                                            )
                                        )}
                                    </Box>

                                    {/* Fee Details */}
                                    <Box
                                        className="secondary-boxes"
                                    >
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                FEES AFTER SCHOLARSHIP (APPROX)
                                            </Typography>
                                            <Typography
                                                className="manage-text-detail"
                                                variant="body2"
                                            >
                                                {program.attributes.fees_after_scholarship}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                CAS DEPOSIT (APPROX)
                                            </Typography>
                                            <Typography
                                                className="manage-text-detail"
                                                variant="body2"
                                            >
                                                 {program.attributes.cas_deposit}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                APP FEES
                                            </Typography>
                                            <Typography
                                                className="manage-text-detail"
                                                variant="body2"
                                            >
                                                {program.attributes.fees}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                OFFER TAT
                                            </Typography>
                                            <Typography
                                                className="manage-text-detail"
                                                variant="body2"
                                            >
                                                {program.attributes.university.offer_tat} Days
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                DURATION
                                            </Typography>
                                            <Typography
                                                className="manage-text-detail"
                                                variant="body2"
                                            >
                                                {program.attributes.duration}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                PROGRAM FIELD
                                            </Typography>
                                            <Typography
                                                className="manage-text-detail"
                                                variant="body2"
                                            >
                                                {program.attributes.program_level}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className="manage-text"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                DISCIPLINE
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className="manage-text-detail"
                                            >
                                                {program.attributes.discipline}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </DashboardBox>
                ))}
            </>
        );
    }

    renderDocumentsTemplates = () => {
        const {documentsAndTemplates} = this.state
        return (
            <>
                {documentsAndTemplates && Object.keys(documentsAndTemplates).map((category) => (
                    <Box className="DocumentsTemplates" key={category}>
                        <Box><Typography className="docCategory">{category}</Typography></Box>
                        <InterviewBox container spacing={4} style={{gap:"10px"}}>
                            {documentsAndTemplates && documentsAndTemplates[category].map((file) => (
                                <Grid item xs={12} sm={12} md={3} className="interviewDocs" key={file.name}>
                                    <Box className="fileDetails">
                                        <Box className="fileIcon">
                                            <img src={fileicon} alt="File Icon" />
                                        </Box>
                                        <Box className="fileNameBox">
                                            <Typography className="fileName">{file.name}</Typography>
                                            <Typography className="fileSize">{file.size}</Typography>
                                        </Box>
                                    </Box>
                                    <DownloadIconButton>
                                        <a
                                            href={file.url}
                                            style={{ "textDecoration": "none", "display": "flex" }}
                                            target="_blank"
                                            data-test-id="viewDocId"
                                            rel="noopener noreferrer"
                                            key={file.name}
                                        >
                                            <DownLoadText>Download</DownLoadText>
                                            <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                                        </a>
                                    </DownloadIconButton>
                                </Grid>
                            ))}
                        </InterviewBox>
                    </Box>
                ))}
            </>
          );
    }

    renderInterview = () => {
        const {interviewPrepMaterial} = this.state
        return (
            <InterviewBox container spacing={4} style={{gap:"10px"}}>
            {interviewPrepMaterial && interviewPrepMaterial.map((file) => (
              <Grid className="interviewDocs" key={file.name} item sm={12} md={3} xs={12}>
                <Box className="fileDetails files">
                  <Box className="fileIcon">
                    <img alt="File Icon" src={fileicon} />
                  </Box>
                  <Box className="fileNameBox files">
                    <Typography className="fileName files">{file.name}</Typography>
                    <Typography className="fileSize files">{file.size}</Typography>
                  </Box>
                </Box>
                <DownloadIconButton data-test-id="DownloadIconButton">
                  <a
                    target="_blank"
                    data-test-id="viewDocId"
                    rel="noopener noreferrer"
                    href={file.url}
                    style={{"textDecoration":"none","display": "flex"}}
                    key={file.name}
                  >
                    <DownLoadText>Download</DownLoadText>
                    <SvgIcon component={FileDownloadIcon} style={{ color: '#64748B' }} />
                  </a>
                </DownloadIconButton>
              </Grid>
            ))}
          </InterviewBox>
        )
    }

    tabPanel(children: {}, value: number, index: number) {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && (
                    <TabBox>
                        <Box className="TabView">{children}</Box>
                    </TabBox>
                )}
            </div>
        );
    };
    // Customizable Area End

    render() {
        return (
            //Merge Engine DefaultContainer
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <AppHeader
                    onHomeClick={this.onHomeClick}
                    onLoginClick={undefined}
                    userName={this.state.userDetails?.attributes?.first_name}
                    onSignUpClick={undefined}
                    toggleData={this.state.toggleDrawer}
                    data-test-id="appHeaderTestId"
                    handleToggle={this.handleToggle}
                    isUserLogin={true}
                    isLogin={false}
                    role={this.state.userDetails?.attributes?.role}
                    profileImg={this.state.userDetails?.attributes?.image}
                />
               <>
                <InstitutionBox>
                <SideMenuBar data-test-id="sidebarNavigationBtn"
                    navigationToAnyPage={this.navigationToAnyPage} activeComponent="Institution" />
                    <Box className="instituteWrapper">
                        <Box className="BoxContainer">
                            <Button className="universityDetailsIdText" data-test-id="BackCourse" onClick={() => {
                                this.navigationToAnyPage("Catalogue")
                            }} startIcon={<ArrowBackIosIcon />} >
                                <span className="universityDetails">University Details- </span><span className="universityTitle">{this.state.university_name}</span>
                            </Button>
                        </Box>
                        {this.renderUniversityDetails()}
                        {this.renderTab()}
                        {this.showErrorToast()}
                        {this.state.MangeShortListModel && this.renderMangeShortlist()}
                    </Box>
                </InstitutionBox>
               </>
            </ThemeProvider>
            // Customizable Area End
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const webStyle = {
    productContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: "white",
        marginTop: "20px",
        fontFamily: "Plus Jakarta Sans",
    },
    productBox: {
        height: 250,
        width: "49%",
        marginRight: "5px",
        flexDirection: "column",
        fontFamily: "Plus Jakarta Sans",
    },
    ImgContainer: {
        height: 150,
    },
    productImg: {
        width: "100%",
        height: "100%",
    },
    detailContent: {
        display: "flex",
        flexDirection: "column",
    },
};

const InstitutionBox = styled(Box)({
    [theme.breakpoints.up("md")]: {
        display:"flex"
      },
    fontFamily: "Plus Jakarta Sans",
    "& .instituteWrapper": {
        backgroundColor: "#F7F7F7",
        padding: "28px 50px",
        "@media (max-width: 600px)": {
            padding: "20px",
            marginLeft: "64px",
        },
    },
    "& .universityDetails": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        color: "#64748B",
        textTransform: "capitalize"
    },
    "& .universityTitle": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        textAlign: "left",
        color: "#475569",
        textTransform: "capitalize"
    },
    "& .BoxContainer": {
        width: "100%",
        marginBottom: "14px",
        "& .universityDetailsIdText": {
            textTransform: "capitalize",
            fontSize: "18px",
            fontFamily: 'Plus Jakarta Sans',
            color: "#64748B",
            "@media (max-width: 425px)": {
                fontSize: "12px",
            },
            "& .MuiSvgIcon-root": {
                fontSize: "16px"
            },
            "& .BoldText": {
                fontWeight: "bold",
                color: "#475569",
                textTransform: "uppercase",
            }
        }
    },
    "& .universityDetailsWrapper": {
        backgroundColor: "#FFFFFF",
        padding: "20px 24px",
        borderRadius:"16px"
    },
    " & .nameAndLogoWrapper": {
        display: "flex",
        gap: "24px",
        alignItems: "center",
        "@media (max-width: 600px)": {
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        },
    },
    "& .universityLogo": {
        width: "72px",
        height: "72px",
        top: "20px",
        left: "24px",
        gap: "0px",
        border: "2px solid transparent",
    },
    "& .nameLocationUniverstity": {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        "@media (max-width: 600px)": {
            alignItems: "center",
        },
    },
    "& .nameUniversity": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "22px",
        fontWeight: "700",
        lineHeight: "27.72px",
        textAlign: "left",
        color: "#212121",
        "@media (max-width: 600px)": {
            fontSize: "18px",
        },
    },
    "& .unversityLocation": {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    "& .locationUniversity": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        textAlign: "left",
        color: "#212121",
        "@media (max-width: 600px)": {
            fontSize: "16px",
        },
    },
    "& .PersonalInfoContainer": {
        marginTop: "20px",
        "& .inputField": {
            backgroundColor: "#fff",
            width: "100%",
            border: "1px solid #CBD5E1",
            borderRadius: "8px",
            height: "50px",
            margin: "5px 0px",
            display: "flex",
            justifyContent: "center",
            padding: "0px 10px",
            boxSizing: "border-box",
            "& .MuiInput-root": {
                "&:focus": {
                    outline: "none"
                },
                "&::before": {
                    content: "none"
                },
                "&::after": {
                    content: "none"
                }
            }
        },
        "& .PersonalInfoGridContainer": {
            "& .Section": {
                padding: "0 15px",
            },
            "& .DetailsContainer": {
                paddingRight: "140px",
                "@media (max-width: 600px)": {
                    paddingRight: "0px",
                    display: "flex",
                    flexDirection: "column",
                },
                "& .gridSection": {
                    "@media (max-width: 600px)": {
                    maxWidth:"100%",
                    flexDirection:"column"
                },
                },
                "& .noBorder":{
                    border: "none"
                },
                "& .universityBasics":{
                    "@media (max-width: 600px)": {
                    maxWidth:"100%",
                }
                },
                "& .LightcolorText": {
                    color: "#64748B",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "18px",
                },
                "& .MuiGrid-item": {
                    borderBottom: '1px solid #E2E8F0',
                    "& .Title": {
                        marginBottom: "10px",
                        marginTop: "10px",
                    },
                    "& .BoldTitle": {
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "22px",
                        textAlign: "left",
                    }
                },
                "& .NameAndDetails": {
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    "& .smallTitle": {
                        minWidth: "70px"
                    }
                }
            }
        }
    },
    "& .DocumentsTemplates": {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    },
    "& .docCategory": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "left",
        color: "#0F172A",
    }
});

const MainTabBox = styled(Box)({
    margin: "30px 0px 0px 0px",
    "& .MuiAppBar-colorPrimary": {
        backgroundColor: "#fff"
    },
    "& .MuiTab-wrapper": {
        color: "#000"
    },
    "& .MuiBox-root-18": { padding: "24px 0px" },

    "& .MuiTabs-indicator ": {
        backgroundColor: "#34D399",
        height: "5px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px"
    },
    "& .Styled(MuiBox)-root-3 .MuiTab-wrapper ": {
        color: "blue !important",
        fontSize: "50px"
    },
    "& .MuiPaper-elevation4": {
        boxShadow: "none",
        borderBottom: "2px solid #E2E8F0"
    },
    "& MuiBox-root-18": {
        backgroundColor: "#fff"
    },
    "& .tabLabel": {
        textTransform: "unset",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20.16px",
        textAlign: "center",
        color:"#212121"
    },
    "& .tabLabel.Mui-selected":{
        textTransform: "unset",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20.16px",
        textAlign: "center",
        color:"#212121"
    },
    "& .MuiTabs-flexContainer": {
        display:"flex",
        justifyContent:"space-between"
    },
    "& .SvgIconAddBranch": {
        color: "#000",
        "@media (min-width: 600px)": {
            color: "#fff",
        },
        "@media (min-width: 600px) and (max-width: 959px)": {
            color: "#000",
        }
    },
    "& .MuiButton-root": {
        textTransform: "none"
    },
    "& .tabBarUniversity": {
        backgroundColor: "#F7F7F7"
    }
});

const TabBox = styled(Box)({
    padding: "24px 0px",
    "& .TabView":{
        display:"flex",
        flexDirection:"column",
        gap:"30px"
    }
})

const OverViewWrapper = styled(Box)({
    "& .aboutUsTitle": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "30px",
        fontWeight: "700",
        lineHeight: "40px",
        letterSpacing: "-0.005em",
        textAlign: "left",
        color: "#212121",
        marginBottom: "12px"
    },
    "& .aboutUsText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "25.6px",
        textAlign: "justify",
        color: "#212121",
    },
    "& .campusLocationBox": {
        marginTop: "60px"
    },
    "& .locationWrapper": {
        display: "flex",
        gap: "24px",
        paddingTop: "32px"
    },
    "& .locationBox": {
        display: "flex",
        gap: "24px",
        backgroundColor: "#FFFFFF",
    },
    "& .locationIcon": {
        width: "24px",
        height: "24px",
        borderRadius: "130px",
        backgroundColor: "#F1F5F9",
        padding: "6px"
    },
    "& .universityLocationName": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "26px",
        textAlign: "left",
        color: "#2A0066",
    },
    "& .universityLocation": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "18px",
        textAlign: "left",
        color: "#000000",
    },
    "& .locationDetails": {
        display: "flex",
        gap: "2px",
        flexDirection: "column"
    }
})

const SlideShowWrapper = styled(Box)({
    position: "relative",
    maxWidth: "80%",
    margin: "auto",
    "& .slides": {
        display: "none",
        width: "100%",
    },
    "& .imageSlide": {
        borderRadius: "8px"
    },
    "& .thumbnail-container": {
        overflowX: "auto",
        whiteSpace: "nowrap",
        marginTop: "10px",
    },
    "& .thumbnails": {
        display: "inline-flex",
        justifyContent: "center",
        gap: "10px",
        marginTop: "10px",
    },
    "& .thumbnailImage": {
        width: "100px",
        cursor: "pointer",
        opacity: 0.6,
        borderRadius: "4px"
    },
    "& .thumbnailImageActive": {
        width: "100px",
        cursor: "pointer",
        opacity: 0.6,
    },
    "& .next": {
        borderRadius: "4px",
        border: "1px solid #CBD5E1",
        position: "absolute",
        top: "40%",
        right: "-15px",
        transform: "translateY(-50%)",
        color: "#64748B",
        backgroundColor: "#FFFFFF",
        height: "50px",
        width: "16px",
        alignContent: "center",
        padding: "10px",
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#FFFFFF"
        },
    },
    "& .prev": {
        borderRadius: "4px",
        border: "1px solid #CBD5E1",
        position: "absolute",
        top: "40%",
        left: "-15px",
        transform: "translateY(-50%)",
        color: "#64748B",
        backgroundColor: "#FFFFFF",
        height: "50px",
        width: "16px",
        alignContent: "center",
        padding: "10px",
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "#FFFFFF"
        },
    }
})

const InterviewBox = styled(Grid)({
    "@media (max-width: 600px)": {
        flexDirection: "column"
    },
    "& .interviewDocs": {
        display:"flex",
        flexDirection:"column",
        backgroundColor:"#FFFFFF",
        gap:"16px",
        flexBasis:"32.33% !important", 
        maxWidth:"32.33% !important",
        "@media (max-width: 600px)": {
            flexBasis: "100% !important",
            maxWidth: "100% !important",
        },
    },
    "& .fileDetails": {
        display:"flex",
        flexDirection:"column",
        gap:"12px"
    },
    "& .fileIcon":{
        height:"42px",
        width:"42px"
    },
    "& .fileNameBox": {
        display:"flex",
        flexDirection:"column",
        gap:"2px"
    },
    "& .fileName": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left",
        color:"#0F172A"
    },
    "& .fileSize": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left",
        color: "#64748B",
    }
})

const DownloadIconButton = styled(Button)(({ theme }) => ({
    width: "100%",
    height: "auto",
    gap: "6px",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "#64748B",
    padding: "10px",
  }));

  const DownLoadText = styled(Typography)(({ theme }) => ({
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "left",
    color: "#64748B",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  }));

  const DashboardBox = styled(Box)({
    background: "#F7F7F7",
    display: "flex",
    fontFamily: "Plus Jakarta Sans",
    "& .mainContainer": {
      padding: "30px",
    },
    "& .secondary-boxes":{
      display:"flex",
      justifyContent:"space-around",
      marginTop:"16px",
      textAlign:"center",
      "@media (max-width: 1440px)": {
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }
    },
    "& .secondary-boxes .MuiBox-root":{
      "@media (max-width: 1200px)": {
        width: '25%',
        margin: '0 2%',
      },
      "@media (max-width: 600px)": {
        width: '42%',
        margin: '0 2%',
      }
    },
    "& .university-logo":{
        height: "72px",
        marginRight: "23px",
        width: "72px",
        borderRadius: "50%",
        "@media (max-width: 1440px)": {
          height: "32px",
        marginRight: "15px",
        width: "32px",
        marginLeft:'8px'
        },
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked":{
      color:'rgb(0, 215, 148)'
    },
    "& .checkboxx": {
      padding: "16px",
      position: "absolute",
      marginTop: "-44px",
      marginLeft: "-34px",
      "@media (max-width: 1440px)": {
        marginTop: '-21px',
        marginLeft: '-32px'
      }
    },
    "& .contentBox": {
      width: "100%",
      boxSizing: "border-box",
      maxWidth: "100%",
      "@media (max-width: 500px)": {
        padding: "20px",
      },
    },
    "& .university-boxes":{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        "@media (max-width: 600px)": {
          flexDirection:'column'
        },
        "& button":{
          "@media (max-width: 600px)": {
            marginTop:'6px'
          },
        },
      },
    "& .content-finder": {
      background: "#fff",
      padding: "42px 42px 42px 38px",
      borderRadius: "5px",
      "@media (max-width: 1440px)": {
        padding: "5px 17px",
      },
    },
    "& .shortListbtn": {
      color: "#FFFFFF",
      border: "1px solid #23CF93",
      borderRadius: "8px",
      textTransform: "unset",
      fontSize: "14px",
      padding:'4px 16px',
      fontFamily: "Plus Jakarta Sans",
      backgroundColor: "#34D399",
      width: "auto",
      maxWidth: "100%",
      "&:hover": {
        backgroundColor: "#34D399",
        color: "#FFFFFF",
      },
      "@media (max-width: 768px)": {
        fontSize: "12px",
      },
    },
    "& .course-heading": {
      fontWeight: "700",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "20px",
      marginBottom:'11px',
      "@media (max-width: 1440px)": {
        fontSize: "16px",
      },
    },
    "& .course-heading-wrap":{
      display: 'flex',
       whiteSpace: 'nowrap',
       "@media (max-width: 768px)": {
        flexDirection:'column'
      },
    },
    "& .university": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "20px",
      marginLeft: "15px",
      color:'212121',
      "@media (max-width: 1440px)": {
       fontSize:'15px',
      },
      "@media (max-width: 768px)": {
        marginLeft: "6px",
        whiteSpace: "normal"
      },
    },
    "& .intake-box": {
      borderBottom: "2px solid #B6373C",
      paddingBottom: "26px",
      "@media (max-width: 768px)": {
        flexDirection: 'column',
        alignItems: 'center',
       }
    },
    "& .intake-box-detail": {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
    
      "@media (max-width: 1440px)": {
        whiteSpace: "unset",
      },
      "@media (max-width: 768px)": {
        marginBottom:'13px',
       },
      "& p": {
        marginRight: "10%",
        fontSize:'16px',
    
        "@media (max-width: 1440px)": {
          marginRight: "5%",
          fontSize:'12px'
        },
      },
    },
    
    "& .statusActive": {
      color: "#3EAA86",
      padding: "8px 25px",
      fontSize: "14px",
      background: "#CFFBE6",
      fontWeight: "700",
      borderRadius: "5px",
      textTransform: "capitalize",
      border: "1px solid #3EAA86",
      "@media (max-width: 1440px)": {
        padding: "5px 20px",
        fontSize:'12px'
      },
    },
    "& .statusInactive": {
      background: "#DBC05433",
      color: "#D97706",
      padding: "8px 25px",
      borderRadius: "5px",
      textTransform: "capitalize",
      fontWeight: "700",
      fontSize: "14px",
      border: "1px solid",
      "@media (max-width: 1440px)": {
        padding: "5px 20px",
        fontSize:'12px'
      },
    },
    "& .manage-text": {
      color: "#4D4D4D",
      fontSize: "12px",
      textAlign: "left",
      fontFamily:'Plus Jakarta Sans',
      fontWeight:'700'
    },
    "& .manage-text-detail": {
      color: "#4D4D4D",
      fontSize: "16px",
      textAlign: "left",
      marginTop: "10px",
      fontFamily:'Plus Jakarta Sans',
      fontWeight:'400',
    },
  
    "& .mainItem": {
      paddingRight: "20px",
      boxSizing: "border-box",
    },
    "& .cardContainer": {
      boxSizing: "border-box",
    },
    "& .cards": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "90px",
      borderRadius: "8px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      paddingLeft: "20px",
      bgcolor: "white",
      boxSizing: "border-box",
      backgroundColor: "#FFFFFF",
    },
  });

  const webStyles = {
    container: {
      display: "grid",
      gridTemplateColumns: "3fr 7fr",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      width: "32rem",
      padding: "0.5rem 1rem",
      minHeight: "12rem",
      gap: "2rem",
    },
    defaultTxt: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
    },
    defaultTxtBold: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
      fontWeight: 700,
    },
    totalCandidates: {
      display: "grid",
      gridTemplateRows: "3fr 7fr",
      alignItems: "start",
      justifyContent: "center",
      justifyItems: "center",
      color: "black",
      margin: "1rem 0rem",
    },
    italicStyle: {
      fontWeight: 500,
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontStyle: "italic",
      color: "#212121",
      opacity: "40%",
    },
    candidatesFontSize: {
      fontSize: "1rem",
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    columnCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    spaceAround: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    paddingRight: {
      paddingRight: "40px",
    },
    cardContent: {
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "1",
      "&. MuiTypography-body1": {
        lineHeight: "1",
      },
    },
    cardTitle: {
      fontWeight: 400,
      fontSize: "14px",
      color: "#64748B",
    },
    fontBig: {
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 400,
      fontSize: "16px",
      color: "#64748B",
    },
    secondCardHeader: {
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 700,
      fontSize: "20px",
      color: "#212121",
    },
    btn: {
      backgroundColor: "blue",
      color: "white",
      borderRadius: "0",
      marginTop: "0.5rem",
      padding: "0.125rem",
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    fontSizeForType: { fontSize: "1 rem" },
    mediumFontSizeForType: { fontSize: "3rem" },
    itemStyle: { margin: "1rem 0rem" },
    type: { fontSize: "0.8rem", textTransform: "capitalize" },
    titletext: {
      color: "grey",
      fontWeight: "lighter",
      fontSize: "3rem",
    },
    typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
    regularFont: {
      fontSize: "14px",
      color: "#212121",
      lineHeight: "17.64px",
      opacity: "80%",
    },
    listPadding: { padding: "0 11px" },
    backgroundColor: {
      fontFamily: "Plus Jakarta Sans",
      background: "#F7F7F7",
    },
    paddingAll: {
      padding: "20px",
    },
  };

  const InputField = styled(TextField)({
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: "#64748B",
        fontWeight: 700,
        opacity: 10,
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
  });

  const StyledCheckBox = styled(Checkbox)({
    borderRadius: "6px",
    width: "20px",
    height: "20px",
    "&.Mui-checked": {
        color: '#3D70B2',
      },
    "@media (min-width: 600px)": {
      width: "18px",
      height: "18px"
    },
    "@media (min-width: 960px)": {
      width: "16px",
      height: "16px"
    }
  });

  const FilterGrid = styled(Grid)({
    "& .filterGridsSearch": {
        display:"flex",
        flexDirection:"column",
        gap:"16px",
        flexBasis:"35% !important", 
        maxWidth:"35% !important",
        "@media (max-width: 600px)": {
            flexBasis: "100% !important",
            maxWidth: "100% !important",
        }
    },
    "& .filterGrids": {
        display:"flex",
        flexDirection:"column",
        gap:"16px",
        flexBasis:"20.85% !important", 
        maxWidth:"20.85% !important",
        "@media (max-width: 600px)": {
            flexBasis: "100% !important",
            maxWidth: "100% !important",
        }
    },
    "& .selectInput": {
        backgroundColor: "#fff",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        margin: "5px 0px",
        justifyContent: "center",
        width: "100%",
        height: "50px",
        boxSizing: "border-box",
        display: "flex",
        padding: "10px 10px",
        "& .MuiInput-root": {
        "&::after": {
            content: "none"
          },
          "&::before": {
            content: "none"
          },
          "&:focus": {
            outline: "none"
          }
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "0px solid red"
        },
        "&:hover:not(.Mui-disabled):after": {
          borderBottom: "0px solid red"
        },
        "&.MuiInput-underline:after": {
          borderBottom: "0px solid red"
        },
        "&.MuiInput-underline:before": {
          borderBottom: "0px solid red"
        },
        "& .MuiSelect-select:focus": {
          backgroundColor: "transparent"
        },
        "& .MuiSelect-icon": {
          color: "#0E172A",
          right: "5px"
        },
      },
  })

  const StyledChip = styled(Chip)({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "4px",
    margin:"4px",
    "& .MuiChip-label": {
      color: "var(--Neutrals-Cool-gray-600, #475569)",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      "@media (min-width: 600px)": {
        fontSize: "13px",
        lineHeight: "20px"
      },
      "@media (min-width: 960px)": {
        fontSize: "12px",
        lineHeight: "18px"
      }
    }
  });

  const MainModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    "& .paper": {
      overflowY: "scroll",
      width: "480px",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      padding: "30px 35px",
      gap: "20px",
      position:"relative",
      height:"auto"
    },
    "& .manageShortlist-list":{
      border: '1px solid #B6373C',
    padding: '9px',
    fontSize: '17px',
    textAlign: 'center',
    borderRadius: '28px',
    marginBottom:'20px',
    },
    "& .manageShortlist-list strong":{
    fontSize: '16px',
    },
    "& .manageShortlist-list p":{
      fontSize: '16px',
      fontWeight:400,
      color: '#4D4D4D',
    padding: '0',
    margin:'0',
      },
      "& .student-select":{
        padding: '8px 15px',
        border: '1px solid #CBD5E1',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'rgb(203, 213, 225)',
        borderRadius: '8px',
        borderBottom: 'none',
        "&::before": {
          display: 'none',
        },
        "&::after": {
          display: 'none', 
        },
      },
      "& .student-select strong":{
        fontSize:'14px',
      },
      "& .student-select .MuiSelect-selectMenu":{
        background:'transparent',
      },
    "& .MangeStaff-paper":{
      overflowY: "scroll",
      borderRadius: "10px",
      display: "flex",
      padding: "30px 35px",
      gap: "20px",
      width: "480px",
      position:"relative",
      height:"auto"
    },
    "& .heading": {
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "Plus Jakarta Sans !important",
      color: "#000000",
      textAlign: "center",
      lineHeight:"24px"
    },
    "& .formFieldBox": {
      width: "100%",
      "& .selectField":{
        "& .MuiSelect-select.Mui-disabled":{
          color:"rgba(0, 0, 0, 0.38)"
        }
      },
      "& .selectInput": {
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#fff",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        margin: "5px 0px",
        height: "50px",
        display: "flex",
        padding: "10px 10px",
        boxSizing: "border-box",
        "& .MuiSelect-icon": {
          color: "#0E172A",
          right: "5px"
        },
        "& .MuiInput-root": {
          "&::before": {
            content: "none"
          },
          "&:focus": {
            outline: "none"
          },
          "&::after": {
            content: "none"
          }
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "0px solid red"
        },
        "&:hover:not(.Mui-disabled):after": {
          borderBottom: "0px solid red"
        },
        "&.MuiInput-underline:before": {
          borderBottom: "0px solid red"
        },
        "&.MuiInput-underline:after": {
          borderBottom: "0px solid red"
        },
        "& .MuiSelect-select:focus": {
          backgroundColor: "transparent"
        }
      },
      "& .intakePlaceholder": {
        "& .MuiAutocomplete-input": {
          color: "rgba(0, 0, 0, 0.87)",
        }
      }
    },
    "& .inputFieldBox": {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom:"10px"
    },
    "& .inputField": {
      width: "100%",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      height: "50px",
      margin: "5px 0px",
      display: "flex",
      justifyContent: "center",
      padding: "0px 10px",
      boxSizing: "border-box",
      "& .MuiInput-root": {
        "&:focus": {
          outline: "none"
        },
        "&::before": {
          content: "none"
        },
        "&::after": {
          content: "none"
        }
      }
    },
    "& .fieldText": {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Plus Jakarta Sans",
      color: "#334155"
    },
    "& .errorText": {
      color: "red"
    },
    "& .starTxt": {
      color: "red",
      fontFamily: "Plus Jakarta Sans !important"
    },
    "& .buttonBox": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      paddingTop:"20px"
    },
    "& .buttonBoxBranch": {
      display:"flex",
      justifyContent: "center",
      paddingTop: "34px",
    }, 
    "& .cancelBtn": {
      color: "rgba(52, 211, 153, 1)",
      background: "rgba(214, 246, 235, 1)",
      borderRadius: "8px",
      width: "45%",
      border: "1px solid rgba(52, 211, 153, 1)",
      textTransform: "none",
      "&.MuiButton-root:hover": {
        background: "rgba(214, 246, 235, 1)",
      }
    },
    "& .addBtn": {
      background: "rgba(52, 211, 153, 1)",
      borderRadius: "8px",
      width: "45%",
      textTransform: "none",
      color: "white",
       fontWeight:"700",
      fontSize:"16px",
      lineHeight:"24px",
      "&.MuiButton-root:hover": {
        background: "rgba(52, 211, 153, 1)"
      }
    },
  });
  const UpdatedMainModelStyle = styled(Paper)({
    fontFamily: "Plus Jakarta Sans !important",
    "& .HeadingContainer" : {
      marginBottom: "20px",
      display:"flex",
      justifyContent:"flex-start"
    },
    "& .NewHeading":{
      color: "rgba(33, 33, 33, 1)",
      fontWeight: 700,
      fontSize: "24px !important",
      fontFamily: "Plus Jakarta Sans !important"
    },
    "& .MangeStaffMobileContainer": {
      width: "100%"
    },
  })
  
  const BoxPopupClose = styled(Box)({
    display: "flex",
    padding: "4px",
    alignItems: "flex-start",
    gap: "10px",
    position: "absolute",
    right: "16px",
    top: "16px",
    "@media (min-width: 600px)": {
      right: "14px",
      top: "14px"
    },
    "@media (min-width: 960px)": {
      right: "12px",
      top: "12px"
    }
  });
// Customizable Area End
