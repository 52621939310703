import React from "react";
// Customizable Area Start
import { Box, Button, Grid, MenuItem, Modal, Select, Typography, styled, withStyles } from "@material-ui/core";
import DashboardOfferController, { Props, configJSON } from "./DashboardOfferController.web";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import { KeyboardArrowDown } from "@material-ui/icons";
import AppHeader from "../../../components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import TodayIcon from "@material-ui/icons/Today";
import Calendar from "react-calendar";
import Tooltip from '@material-ui/core/Tooltip';
import * as IMG_CONST from "./assets";
// Customizable Area End
export default class Block extends DashboardOfferController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderModal = () => {
        return (
            <FilterModal open={this.state.open} data-test-id="modalTestID"
                onClose={this.handleOpenClose}>
                <ModalBody>
                    <span className="closeBtn" onClick={this.handleOpenClose}>
                        <CloseIcon />
                    </span>
                    <ModalContent>
                        <Box className="modalHeader">
                            <FilterListIcon className="filterIcon" />
                            <Typography
                                data-test-id="filterText"
                            >{configJSON.filterText}</Typography>
                        </Box>
                        <Box className="inputBoxes">
                            <Box className="inputBox">
                                <Typography className="modalHeaderTxt">{configJSON.universityText}</Typography>
                                <SelectInput
                                    fullWidth
                                    displayEmpty
                                    MenuProps={{
                                        disableScrollLock: true,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    style={{
                                        color: this.state.universityValue == "none" ? "#999" : "#000"
                                    }}
                                    value={this.state.universityValue}
                                    data-test-id="universityTestId"
                                    IconComponent={KeyboardArrowDown}
                                    onChange={this.universityChange}
                                >
                                    {this.state.universityValue == "none" && (
                                        <MenuItem disabled value="none">
                                            {configJSON.universiyPrompt}
                                        </MenuItem>
                                    )}
                                    {this.state.universityData.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </SelectInput>
                            </Box>
                            <Box className="inputBox">
                                <Typography className="modalHeaderTxt">{configJSON.expiryDateText}</Typography>
                                    <Box className="inputFieldBox">
                                        <Box
                                            className="inputFieldCalender"
                                            onClick={this.onCalenderClick}
                                            data-test-id="dateTestID"
                                        >
                                            <Typography className="calendarTxt" style={{ color: this.state.expiryDateValue == configJSON.expiryPlaceholder ? "#999" : "#000" }} data-test-id="calenderValueTestId">{this.state.expiryDateValue?.toString()}</Typography>
                                            <TodayIcon className="calendarIcon" />
                                        </Box>
                                        {this.state.isCalender &&
                                            <Calendar
                                                onClickDay={this.handleDateChange}
                                                value={this.state.expiryDate}
                                                className="calenderBox"
                                                data-test-id="calenderTestID"
                                                minDate={new Date()}
                                            />
                                        }
                                    </Box>
                            </Box>
                            <Box className="btnBox">
                                <Button
                                    data-test-id="resetBtnTestID"
                                    className="resetFilter btnInside"
                                    onClick={this.onResetClick}>
                                    {configJSON.resetFilterText}
                                </Button>
                                <Button
                                    data-test-id="applyFilterTestID"

                                    onClick={this.handlApplyFilter}
                                    className="applyFilter btnInside"
                                >
                                    {configJSON.applyFilterText}
                                </Button>
                            </Box>
                        </Box>
                    </ModalContent>
                </ModalBody>
            </FilterModal>
        )
    };
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box>
                <AppHeader
                    onHomeClick={this.onHomeClick}
                    onLoginClick={undefined}
                    onSignUpClick={undefined}
                    toggleData={this.state.toggleDrawer}
                    data-test-id="appHeaderTestId"
                    handleToggle={this.handleToggle}
                    isUserLogin={true}
                    isLogin={false}
                    userName={this.state.userDetails?.attributes?.first_name}
                    role={this.state.userDetails?.attributes?.role}
                    profileImg={this.state.userDetails?.attributes?.image}
                />
                <DashboardBox>
                <SideMenuBar data-test-id="sidebarNavigationBtn"
                    navigationToAnyPage={this.navigationToAnyPage} activeComponent="DashBoard" />
                <Wrapper>
                    <Header>
                        <Typography className="offersHeader">
                            {configJSON.offersHeaderText}
                        </Typography>
                        <Button
                            data-test-id="clearFilterTestId"
                            endIcon={
                                this.state.filterCount !=="" && (
                                    <CloseIcon
                                    data-test-id="clearInnerFilterTestId"
                                        onClick={this.handleClearFilter}
                                        className="closeIcon"
                                    />
                                )
                            }
                            startIcon={
                                this.state.filterCount !=="" ? (
                                    <span className="filterCount">{this.state.filterCount}</span>
                                ) : (
                                    <FilterListIcon />
                                )
                            }
                            className={
                                this.state.filterCount !=="" ? "btnFilter filtered" : "btnFilter"
                            }
                            onClick={this.handleOpenClose}
                        >
                            {configJSON.filterText} { this.state.filterCount !=="" && configJSON.filterAppliedText}
                        </Button>
                    </Header>
                    <ContentBox container spacing={2}>
                            {this.state.offersData.length > 0 ? (
                                this.state.offersData.map((item) => (
                                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={item.id}>
                                        <Box className="innerMainBox">
                                            <Box>
                                                <Box className="toolTipBox"
                                                >
                                                    <Typography className="universityText">
                                                        {item?.attributes?.title}
                                                    </Typography>
                                                  
                                                    {this.state.openTooltipId === item.id && (
                                                        <TooltipStyle
                                                        data-test-id="toolTipCloseId"
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            onClose={this.handleTooltipClose}
                                                            open
                                                            disableHoverListener
                                                            disableFocusListener
                                                            disableTouchListener
                                                            title={item.attributes.terms_and_conditions}
                                                            arrow
                                                        >
                                                            <img
                                                                src={IMG_CONST.infoIcon}
                                                                className="infoIcon"
                                                                onClick={() => this.handleTooltipClose()}
                                                                data-test-id="imageTestId"
                                                                />
                                                        </TooltipStyle>
                                                    )}
                                                    {this.state.openTooltipId !== item.id && (
                                                        <img
                                                        data-test-id="toolTipOpenId"
                                                            className="infoIcon"
                                                            src={IMG_CONST.infoIcon}
                                                            onClick={() => this.handleTooltipOpen(item.id)}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="middleBox">
                                                <Typography className="universityDescription">
                                                    {item.attributes.description} 
                                                </Typography>
                                            </Box>
                                            <Box className="txtBox">
                                                <Typography className="leftSideTxt">
                                                    {configJSON.offeredBy}
                                                </Typography>
                                                <Typography  className="rightSideTxt universityTxt">{item.attributes.university}</Typography>
                                            </Box>
                                            <Box className="txtBox">
                                                <Typography className="leftSideTxt">{configJSON.promoCodeText}</Typography>
                                                <Typography className="rightSideTxt">{item.attributes.promo_code}</Typography>
                                            </Box>
                                            <Box className="txtBox">
                                                <Typography className="leftSideTxt">{configJSON.discountValueText}</Typography>
                                                <Typography className="rightSideTxt">{item.attributes.discount_value}</Typography>
                                            </Box>
                                            <Box className="txtBox">
                                                <Typography className="leftSideTxt">{configJSON.promotionEndsOnText}</Typography>
                                                <Typography className="rightSideTxt">{item.attributes.validity_period}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                            ) : (
                                <Box 
                                className="noOfferBox"
                                >
                                    <Typography>{configJSON.noDataFound}</Typography>
                                </Box>
                            )}

                    </ContentBox>
                    {this.renderModal()}
                </Wrapper>
                </DashboardBox>
            </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area Start

const TooltipStyle = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#0F172A",
      padding: "15px",
      color: "#FFFFFF",
      fontFamily: "Plus Jakarta Sans",
      fontSize: "13px",
      marginTop:"10px"
    },
    arrow: {
      color: "#0F172A"
    },
  }))(Tooltip);
  
const SelectInput = styled(Select)({
    backgroundColor: "#fff",
    width: "350px",
    border: "1px solid #CBD5E1",
    margin: "5px 0px",
    borderRadius: "8px",
    height: "50px",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    display: "flex",
    "@media(max-width:500px)": {
        width: "250px"  
    },
    "& .MuiSelect-icon": {
        color: "#0E172A",
        right: "5px"
    },
    "& .MuiInput-root": {
        "&::before": {
            content: "none"
        },
        "&:focus": {
            outline: "none"
        },
        "&::after": {
            content: "none"
        }
    },
    "&.MuiInput-underline:before": {
        borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
        borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
        borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
    }
});

const Wrapper = styled(Box)(({ theme }) => ({
    background: "#f7f7f7",
    padding: "50px",
    width: "100%",
    "@media (max-width: 500px)": {
        padding: "20px"
    },
    [theme.breakpoints.down("md")]: {
        marginLeft: "64px",
    },
}));

const Header = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    gap:"20px",
    "& .filterCount": {
        background: "white",
        color: "black",
        padding: "5px",
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Plus Jakarta Sans",
        "@media(max-width:350px)": {
            fontSize: "8px !important",
            padding: "8px",
            width: "8px",
            height: "8px"
        }
    },

    alignItems: "center",
    "& .offersHeader": {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Plus Jakarta Sans",
        "@media(max-width:340px)": {
            fontSize: "14px"
        }
    },
    "& .btnFilter": {
        background: "#000",
        color: "#fff",
        padding: "8px 30px",
        fontWeight: 700,
        textTransform: "none",
        fontSize: "14px",
        fontFamily: "Plus Jakarta Sans",
        "&:hover": {
            background: "#000",
            color: "#fff"
        },
        "@media(max-width:350px)": {
            padding: "4px 6px",
            fontSize: "8px"

        }
    },
    "& .filtered": {
        padding: "8px 20px"
    },

    "& .closeIcon": {
        "@media(max-width:350px)": {
            fontSize: "8px !important"
        }
    }
});

const ContentBox = styled(Grid)({
    padding: "30px 0px",
    position: "relative",
    "& .noOfferBox":{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        color: "#64748B",
        fontSize: "16px",
        fontFamily: "Plus Jakarta Sans",
        padding: "20px"
    },
    "& .infoIcon":{
        height:"min-height",
        marginTop: "5px"
    },
    "& .toolTipBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    "& .iconStyle": {
        position: "relative",
        color: "#FFFF",
        border: "0px solid #0F172A",
        borderRadius: "50%",
        backgroundColor: "#0F172A",
        cursor: "pointer"
    },
    "& .innerMainBox": {
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        justifyContent: "space-between",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
    }, "& .universityText": {
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: "Plus Jakarta Sans",
        color: "#0F172A",
        height: "55px"
    }, "& .subHeadingTxt": {
        fontWeight: 400,
        fontSize: "13px",
        fontFamily: "Plus Jakarta Sans",
        color: "#64748B",
        textAlign: "center",

    },
    "& .middleBox": {
        display: "flex", gap: "5px", flexDirection: "column",
        height: "80px"
    },
    "& .universityDescription": {
        fontWeight: 500,
        fontSize: "13px",
        fontFamily: "Plus Jakarta Sans",
        color: "#212121",
        maxHeight: "80px",
        overflow: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        scrollbarWidth: "thin", 
        "&::-webkit-scrollbar": {
            width: "5px" 
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "10px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1"
        }
    },
    
    "& .leftSideTxt": {
        fontWeight: 400,
        fontFamily: "Plus Jakarta Sans",
        fontSize: "13px",
        color: "#64748B"
    },
    "& .rightSideTxt": {
        fontWeight: 700,
        fontFamily: "Plus Jakarta Sans",
        fontSize: "13px",
        color: "#0F172A",
        width:"100px",
        textAlign:"end",
    },
    "& .universityTxt":{
        width: "200px",
        height:"40px",
        "@media(max-width:500px)": {
            width: "180px",
            height:"auto"
        }
    },
    "& .txtBox": {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #E2E8F0",
        padding:"0px 0px 10px 0px"
    }
});

const FilterModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const ModalBody = styled(Box)({
    borderRadius: "8px",
    width: "380px",
    "@media(max-width:500px)": {
        width: "250px"  
    },
    background: "white",
    border: "none",
    position: "relative",
    padding: "32px 16px",
    "& .closeBtn": {
        position: "absolute",
        top: "20px",
        right: "20px",
        cursor: "pointer"
    }
});

const ModalContent = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    "& .modalHeader": {
        display: "flex",
        gap: "20px",
        "& .filterIcon, p": {
            fontWeight: 700
        }
    },

    "& .inputBoxes": {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "& .inputBox": {
            "@media(max-width:500px)": {
                width: "250px"
            },
            "& input": {
                border: "1px solid #CBD5E1",
                height: "30px",
                borderRadius: "8px",
                padding: "10px",
            }
        }
    },
    "& .btnBox": {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        gap:"20px",
        "& .btnInside": {
            borderRadius: "8px"
        },
        "& .resetFilter": {
            background: "#D6F6EB",
            color: "#059669",
            border: "1px solid #34D399",
            fontFamily: "Plus Jakarta Sans",
            textTransform: "none",
            padding: "5px 20px",
            fontSize: "14px"
        },
        "& .applyFilter": {
            background: "#34D399",
            color: "#FFFFFF",
            border: "1px solid #34D399",
            fontFamily: "Plus Jakarta Sans",
            textTransform: "none",
            padding: "5px 20px",
            fontSize: "14px"
        }
    },

    "& .formFieldBox": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column"
    },

    "& .inputFieldBox": {
        boxSizing: "border-box",
        width: "100%"
    },

"& .modalHeaderTxt":{
    fontSize: "16px",
    fontFamily: "Plus Jakarta Sans",
    color: "#334155",
    fontWeight: 700
},
    "& .inputFieldCalender": {
        backgroundColor: "#fff",
        width: "100%",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "50px",
        margin: "5px 0px",
        display: "flex",
        padding: "0px 10px",
        boxSizing: "border-box",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "space-between",
        "& .MuiInput-root": {
            "&:focus": {
                outline: "none"
            },
            "&::before": {
                content: "none"
            },
            "&::after": {
                content: "none"
            },
        }, "& .calendarIcon": {
            color: "#000"
        }
    }
});

const DashboardBox = styled(Box)({
    background: "#F7F7F7",
    display: "flex"
  });
// Customizable Area End