export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editImg = require("../assets/message-edit.png");
export const accountOutline = require("../assets/account-outline.svg")
export const Phone_Icon = require("../assets/Phone_icon.png")
export const SmsIcon = require("../assets/sms.png")
export const CalenderIcon = require("../assets/calender.png")
export const defaultImg = require("../assets/defaultImg.png");
export const DragAndDrop = require("../assets/DRAGANDDROP.svg")
export const Tips = require("../assets/tips.png")
export const LocationIcon = require("../assets/LocationIcon.png")
export const CourtHouseIcon = require("../assets/CourtHouse.png")
export const CalliconforAssign = require("../assets/CalliconforAssign.png")
export const infoIcon = require("../assets/infoIcon.svg")